import React, { useContext } from 'react';
import YearMenu from './YearMenu';
import StudentContext from '../../context/StudentContext';

const Year = ({ year, children }) => {
  //console.log(props.children);
  //const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const { dispatch } = useContext(StudentContext);

  //const [menuOpen, setMenuOpen] = useState(false);

  // const menuOpenHandler = (e) => {
  //   e.stopPropagation();
  //   if (menuOpen) {
  //     setMenuOpen(false);
  //   } else {
  //     setMenuOpen(true);
  //     setMenuPosition({ x: e.clientX, y: e.clientY + 20 });
  //   }
  // };

  return (
    <div className={'pl-4 pt-4 snap-center'}>
      <div
        className={'static flex flex-row items-center gap-x-2.5 pb-2.5 pl-4'}
      >
        <h1
          className={'text-3xl font-sans text-secondary font-bold capitalize'}
          onClick={(e) => {
            dispatch({
              type: 'ADD_STUDY_PERIOD',
              year,
              studyPeriod: 'Trimester3',
            });
          }}
        >
          Year {year}
        </h1>
        <YearMenu
          yearNo={year}
          //menuOpen={menuOpen}
          //setMenuOpen={setMenuOpen}
          //x={menuPosition.x}
          //y={menuPosition.y}
        />
        
      </div>
      <div
        className={'flex flex-row space-x-2.5 h-fit'}
        key={year}
      >
        <div
          className={'flex flex-row space-x-5'}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Year;
// google the canvas thing business model google images
