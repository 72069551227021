import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {IconContext} from 'react-icons';
import {AiOutlineMinus} from 'react-icons/ai';
import {FiEdit} from 'react-icons/fi'

const Selection = ({name, editHandler, removeHandler, onClick, clicked, size}) => {

    const mainStyle = clicked ?
        'border border-solid rounded border-1 border-uon-gray w-full h-fit p-1 flex flex-row items-center cursor-pointer select-none hover:border-uon-blue-700 bg-uon-blue-50'
        :
        'border border-solid rounded border-1 border-uon-gray w-full h-fit p-1 flex flex-row items-center cursor-pointer select-none hover:border-uon-blue-700 bg-white'

    let text = 'text-uon-gray font-sans';

    if(size === 'medium') text += ' text-sm font-normal';
    if(size === 'small') text += ' text-xs';

    return (
        <div
            key={name}
            onClick={onClick}
            className={mainStyle}

        >
            <p className={text}>{name}</p>
            <div className={'grow flex flex-row items-center justify-end gap-x-2'}>
                <IconContext.Provider value={{size: '1.2em'}}>
                    <FiEdit className={'text-uon-gray cursor-pointer hover:bg-stone-200'} onClick={editHandler}/>
                    <AiOutlineMinus className={'text-red-500 cursor-pointer hover:bg-red-100'} onClick={removeHandler}/>
                </IconContext.Provider>
            </div>

        </div>
    );
}

Selection.defaultProps = {
    name: 'ERROR ERROR ERROR'
}

Selection.propTypes  = {
    name: PropTypes.string.isRequired,
    editHandler: PropTypes.func,
    removeHandler: PropTypes.func,
    onClick: PropTypes.func.isRequired
}

export default Selection;