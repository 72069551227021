import React, { useState } from 'react';
import Select from 'react-select';

const DropDownBox = ({value,options,onChange, isMulti}) => {
    // var [multi, setMultiSelect] = useState();
    var Value = (options, value) => {
        // setMultiSelect=(Array.isArray(value) ? value.map(m => m.options): []);
        return options ? options.find(option => option.value === value): "";
    }
    return(
        <div>
            <Select
            className='mb-2.5 text-gray-900 text-lg rounded-lg focus:ring-blue-500 
            focus:border-blue-500 block w-80 p-0.5 dark:bg-gray-700 dark:border-gray-600
            dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
             placeholder={'Select...'}
             isMulti={isMulti}
             value={Value(options, value)}
             onChange={value => onChange(value)}
             options={options}
             />
        </div>
    )
}
export default DropDownBox;