import React, { useState } from 'react';
import SelectPrerequisite from './DropDownBox';

const CreatePrerequisite = ({open, group, eachGroupValue, deleteGroup, addGroup,formik, prerequisiteOption}) => {
    
    return (
<form>
        <div className='select-non text-white text-3xl gap-48 flex'>
        <div className='absolute top-0 left-0 bg-uon-blue-500 text-white w-full'>
        <label className='ml-1.5'>CREATE NEW PREREQUISITE</label>
        </div>
        <div>
          <label 
           htmlFor='create-Prerequisite-modal'
           className='btn btn-sm absolute right-0 top-0 text-3xl bg-uon-blue-500 border-uon-blue-500 text-white'
        >X</label></div>
        </div><br/>
        <div>
        {open ? group.map((eachGroup,index) => (  
                  <div key={index}>
                  <p className='text-2xl'>Group {index + 1}</p>
                  <p>Only one pre-requisite in each group will be required.</p>
                  <div className='justify-left mt-4 flex'>
                  <SelectPrerequisite
                    type="text"
                    id='prerequisite'
                    isMulti={true}
                    options={prerequisiteOption}
                    value={eachGroup.group}
                    onChange={(e) => eachGroupValue(e, index)}
                    />
                    {group.length > 1 && (<label className='btn btn-sm mt-1.5' onClick={() => deleteGroup(index)}>X</label>)}
                  </div>
                  {group.length - 1 === index && group.length < 4 && (<label className='btn text-xl text-white rounded-full' onClick={addGroup}>Add Group  +</label>)}
                   {formik.touched.prerequisite && formik.errors.prerequisite ? <p className='text-error'>{formik.errors.prerequisite}</p> : null}
                  </div>       
        )) : "" }
        </div><br/>
        <div className='flex flex-row gap-2'>
        <label
            htmlFor='create-Prerequisite-modal'
            className='btn btn-outline w-32 text-2xl capitalize'
          >
            Cancel
          </label>
          <label
            htmlFor='create-Prerequisite-modal'
            className='btn btn-primary w-32 text-white text-3xl capitalize'
          >
            Add
          </label>
        </div>
  </form>
    );
};

export default CreatePrerequisite;