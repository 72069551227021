import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { ReactComponent as UonLogo } from '../assets/svg/uonLogo.svg';
import visibilityIcon from '../assets/svg/visibilityIcon.svg';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase.config';

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const { name, email, password } = formData;

  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      updateProfile(auth.currentUser, { displayName: name });

      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();

      await setDoc(doc(db, 'users', user.uid), formDataCopy);

      navigate('/');
    } catch (error) {
      toast.error('Something went wrong with registration');
    }
  };

  return (
    <>
      <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-md w-full space-y-8'>
          <div>
            <UonLogo className='mx-auto w-auto' />

            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              Sign up to create an account
            </h2>
            <p className='mt-2 text-center text-sm text-gray-600'>
              Or{' '}
              <Link
                to='/sign-in'
                className='font-medium text-uon-blue-500 hover:text-uon-blue-600'
              >
                Sign In Instead
              </Link>
            </p>
          </div>
          <form
            className='mt-4 border border-transparent rounded shadow-lg p-10'
            onSubmit={onSubmit}
          >
            <div className='form-control w-full'>
              {/* name div */}
              <div className='form-control w-full'>
                <label htmlFor='name' className='label'>
                  <span className='label-text'>Name</span>
                </label>
                <input
                  type='text'
                  className='input input-bordered input-md w-full'
                  placeholder='Name'
                  id='name'
                  value={name}
                  onChange={onChange}
                />
              </div>

              {/* Email div */}
              <div className='form-control w-full'>
                <label htmlFor='email' className='label'>
                  <span className='label-text'>Email Address</span>
                </label>
                <input
                  type='email'
                  className='input input-bordered input-md w-full'
                  placeholder='Email address'
                  id='email'
                  value={email}
                  onChange={onChange}
                />
              </div>

              {/* Password div */}
              <div
                className='form-control w-full'
                style={{ position: 'relative' }}
              >
                <label htmlFor='password' className='label'>
                  <span className='label-text'>Password</span>
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className='input input-bordered input-md w-full passwordInput'
                  placeholder='Password'
                  id='password'
                  autoComplete='current-password'
                  required
                  value={password}
                  onChange={onChange}
                />
                <label htmlFor='password' className='label'>
                  <span className='label-text-alt'>
                    <Link
                      to='/forgot-password'
                      className='text-uon-blue-500 hover:text-uon-blue-600'
                    >
                      Forgot Password
                    </Link>
                  </span>
                </label>

                {/* visibility icon here */}
                <img
                  src={visibilityIcon}
                  alt='show password'
                  className='w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-3 opacity-60 hover:opactiy-100'
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              </div>
            </div>

            {/* Sign in button here */}
            <button className='btn bg-uon-blue-500 hover:bg-uon-blue-600 border-none text-white w-full'>
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignUp;
