function Spinner() {
  return (
    <div className='flex h-screen'>
      <div className='m-auto'>
        <progress className='progress progress-primary w-56'></progress>
      </div>
    </div>
  );
}

export default Spinner;
