import React, { useState, useContext } from 'react';
import { IconContext } from 'react-icons';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import {FiEdit} from "react-icons/fi";
import {AiOutlineMinus} from "react-icons/ai";
// import fetch from "node-fetch";

const AdminCourse = ({
                    id,
                    name,
                    code,
                    editHandler,
                    removeHandler,
                    location
                }) => {

    const [hover, setHover] = useState(false);


    let middleText;

    if (hover) {
        middleText = name;
    } else {
        middleText = name;
    }
    //id='courseCode' onClick={openCoInfo.bind(this, id)}
    return (
        <div
            key={id}
            draggable={true}
            onDragStart={(e) => {
                e.dataTransfer.setData('courseId', id);
                e.dataTransfer.setData('location', location);
                //e.dataTransfer.setData('studyPeriodId', studyPeriodId);
            }}
            className='relative w-28 h-28 items-center border border-solid py-0.5 gap-y-0.5 gap-1.5 rounded-md cursor-move
       bg-white border-stone-700 hover:border-blue-500 select-none'
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className='w-28'>
                <p
                    id={'courseCode'}
                    className='text-center text-base font-sans font-bold uppercase
          text-uon-blue-500 hover:text-uon-blue-600'
                >
                    {code}
                </p>
            </div>

            <div className='flex w-28 h-14 px-1 justify-center items-center'>
                <p
                    className='text-center text-xs font-sans text-capitalize leading-normal line-clamp-3
          text-stone-600'
                >
                    {middleText}
                </p>
            </div>

            <div className='absolute w-28 bottom-0'>
                <div className={'grow flex flex-row items-center justify-center gap-x-2 py-1'}>
                    <IconContext.Provider value={{size: '1.2em'}}>
                        <FiEdit className={'text-uon-gray cursor-pointer hover:bg-stone-200'} onClick={editHandler}/>
                        <AiOutlineMinus className={'text-red-500 cursor-pointer hover:bg-red-100'} onClick={removeHandler}/>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
};

export default AdminCourse;