import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Selection from './Selection';
import SearchBar from '../../SearchBar';

const Sidebar = ({arrayOfData, selection, setSelection, placeholder, heading, subheading, editHandler, removeHandler, selectionSize}) => {

    const [filteredData, setFilteredData] = useState(arrayOfData || []);

    useEffect(() => {
        setFilteredData(arrayOfData);
    }, [arrayOfData]);

    return (
        <div className={'flex flex-col px-2.5 gap-y-10 py-1.5 border-uon-gray h-full w-full bg-white shadow-lg select-none'}>
            <div className={'flex flex-col gap-y-3.5'}>
                <p className={'font-sans text-3xl underline text-uon-blue-500'}>{heading} </p>
                <p className={'font-sans text-md text-uon-gray'}>{subheading}</p>
            </div>
            <SearchBar placeholder={placeholder} setFilteredData={setFilteredData} arrayOfData={arrayOfData}/>
            <div className={'gap-y-2.5 flex flex-col h-full'}>
            <hr/>
                <div className={'gap-y-2.5 flex flex-col overflow-y-auto scroll-smooth h-96'}>
                    {filteredData.map(data =>
                        <Selection
                            onClick={(e) => {
                                e.stopPropagation();
                                console.log(data, selection);
                                if(selection ? selection.id === data.id : false) setSelection(undefined);
                                else setSelection(data);
                            }}
                            name={data.value1}
                            clicked={selection ? selection.id === data.id : false}
                            editHandler={editHandler}
                            removeHandler={removeHandler}
                            size={selectionSize}
                        />
                    )}
                </div>
            </div>
        </div>
    ) ;
}
Sidebar.defaultProps = {
    heading: "Degrees",
    subheading: "Select, edit or remove degrees.",
    placeholder: "Search degrees",
    data: ["Computer Science", "Engineering", "Environmental Science", "Physics", "Maths", "dtime", "time", "dtim", "timely", "times"]
}

Sidebar.propTypes = {
    arrayOfData: PropTypes.arrayOf(PropTypes.shape({
        value1: PropTypes.string.isRequired,
        value2: PropTypes.string,
        id: PropTypes.number.isRequired
    }))
}
export default Sidebar;