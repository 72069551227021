// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// For Firestore (maybe not necessary?)
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyClUPVrT_T4WtcJWKxrfIjazJYft4VIafE',
  authDomain: 'program-planner-app.firebaseapp.com',
  projectId: 'program-planner-app',
  storageBucket: 'program-planner-app.appspot.com',
  messagingSenderId: '921986236147',
  appId: '1:921986236147:web:7c663f1e28ee5e09284186',
};

// Initialize Firebase
initializeApp(firebaseConfig);
// vvv possibly not necessary
export const db = getFirestore();
