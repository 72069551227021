import WarningClass, {WarningType} from "./WarningClass";
import studyPeriod from "../components/Student/StudyPeriod";


class StudyPeriodClass{

    /**
     *
     * @param id {int}
     * @param year {int} year number of the year in state
     * @param availabilityId {string}
     * @param courseIds {int[]}
     * @param preferredUnits {int}
     * @param units {int}
     * @param error {WarningClass}
     * @param warnings  {WarningClass[]}
     * @param groupsOfPreReqIdsAndCourseId {{courseId: int, groupOfPreReqIds: int[]}[]}
     */
    constructor(id, year, availabilityId, courseIds, preferredUnits, units, error, warnings, groupsOfPreReqIdsAndCourseId) {
        /**
         * the id of this study-period
         * @type {Number}
         */
        this.id = id;
        this.year = year;
        this.availabilityId = availabilityId;
        this.courseIds = courseIds;
        this.preferredUnits = preferredUnits;
        this.units = units;
        this.error = error;
        this.warnings = warnings;
        this.groupsOfPreReqIdsAndCourseId = groupsOfPreReqIdsAndCourseId;
    }

    /**
     *
     * @param courseId {int}
     * @returns {boolean}
     */
    isCourseInStudyPeriod(courseId){
        //course is already in a study-period
        return this.courseIds.includes(courseId);
    }

    /**
     *
     * @param course {CourseClass}
     * @param state {StateClass}
     * @return {WarningClass}
     */
    setError(course, state) {
        if (!this.isAvailableInStudyPeriod(course)){
            this.error = new WarningClass(WarningType.NOT_AVAILABLE_IN_STUDY_PERIOD, course.courseId, this.id);
        }
        else if (state.similarCourseIsComplete(course)) {
            this.error = new WarningClass(WarningType.SIMILAR_COURSE_COMPLETED, course.courseId, this.id);
        }
        else if (state.isCourseInAStudyPeriod(course.courseId)){
            this.error = new WarningClass(WarningType.ALREADY_IN_A_STUDY_PERIOD, course.courseId, this.id);
        }
        else if (state.isCourseCompleted(course.courseId)) {
            this.error = new WarningClass(WarningType.ALREADY_COMPLETED, course.courseId, this.id);
        }
        else if (state.similarCourseInAStudyPeriod(course)){
            this.error = new WarningClass(WarningType.SIMILAR_COURSE_IN_A_STUDY_PERIOD, course.courseId, this.id);
        }
        else if(!state.preReqsAreMet(course, this)){
            this.error = new WarningClass(WarningType.PRE_REQS_NOT_MET, course.courseId, this.id);
        }
        else if(this.getUnitsOver(0) !== -1){
            this.error = new WarningClass(WarningType.OVER_UNITS, course.courseId, this.id);
        }
        else {
            this.error = new WarningClass(WarningType.NO_PROBLEMS, course.courseId, this.id);
        }
    }

    /**
     *
     * @param courseId {int}
     * @param state {StateClass}
     * @return {WarningClass}
     */
    getError(courseId, state) {
        if(!courseId) return null;
        const course = state.getCourse(courseId);
        if (!this.isAvailableInStudyPeriod(course)){
            return new WarningClass(WarningType.NOT_AVAILABLE_IN_STUDY_PERIOD, course.courseId, this.id);
        }
        else if (state.similarCourseIsComplete(course)) {
            return new WarningClass(WarningType.SIMILAR_COURSE_COMPLETED, course.courseId, this.id);
        }
        else if (state.isCourseInAStudyPeriod(course.courseId)){
            return new WarningClass(WarningType.ALREADY_IN_A_STUDY_PERIOD, course.courseId, this.id);
        }
        else if (state.isCourseCompleted(course.courseId)) {
            return new WarningClass(WarningType.ALREADY_COMPLETED, course.courseId, this.id);
        }
        else if (state.similarCourseInAStudyPeriod(course)){
            return new WarningClass(WarningType.SIMILAR_COURSE_IN_A_STUDY_PERIOD, course.courseId, this.id);
        }
        else if(state.getGroupsOfUnmetPreReqs(course, studyPeriod).length !== 0){
            return new WarningClass(WarningType.PRE_REQS_NOT_MET, course.courseId, this.id);
        }
        else if(this.getUnitsOver(0) !== -1){
            return new WarningClass(WarningType.OVER_UNITS, course.courseId, this.id);
        }
        else {
            return new WarningClass(WarningType.NO_PROBLEMS, course.courseId, this.id);
        }
    }

    removeError(){
        this.error = null;
    }

    /**
     *
     * @param availabilityId {string}
     * @param state {StateClass}
     */
    setAvailabilityId(availabilityId, state){
        this.availabilityId = availabilityId;
        //remove courses that are no longer available in this studyPeriod
        this.courseIds
            .map(courseId => state.getCourse(courseId))
            .filter(course => !this.isAvailableInStudyPeriod(course))
            .forEach(course => state.removeCourseFromStudyPeriod(course.courseId, course.units, this));
    }

    /**
     * used when removing course from this study-period
     * test if units warning is valid and remove
     * @param courseId {int}
     * @param unitsToAdd {int} set to negative if removing course
     */
    updateUnits = (courseId, unitsToAdd) => {
        this.units += unitsToAdd;
        const unitsOver = this.getUnitsOver(0);
        this.warnings = this.warnings.filter(warning => warning.type !== WarningType.OVER_UNITS);
        if(unitsOver !== -1){
            this.warnings.push(new WarningClass(WarningType.OVER_UNITS, courseId, this.id, undefined, unitsOver));
        }
    }

    /**
     *
     * @param courseId {int}
     * @param preferredUnits {int}
     */
    setPreferredUnits = (courseId, preferredUnits) => {
        this.preferredUnits = preferredUnits;
        const unitsOver = this.getUnitsOver(0);
        this.warnings = this.warnings.filter(warning => warning.type !== WarningType.OVER_UNITS);
        if(unitsOver !== -1){
            this.warnings.push(new WarningClass(WarningType.OVER_UNITS, courseId, this.id, undefined, unitsOver));
        }
    }

    /**
     *
     * @param courseId {int}
     * @param groupsOfPreRequisites {int[][]}
     */
    addGroupsOfPreReqs = (courseId, groupsOfPreRequisites) =>{
        groupsOfPreRequisites.forEach(groupOfPreRequisites => {
            this.groupsOfPreReqIdsAndCourseId.push({courseId, groupOfPreReqIds: groupOfPreRequisites});
        })
    }

    /**
     *  returns true if course is in the study-period
     * @param course {CourseClass}
     * @returns { boolean }
     */
    isAvailableInStudyPeriod = (course) => {
        //* course is not available during the given study-period
        return !(course.availabilities === [] ||
            !course.availabilities.includes(this.availabilityId));
    }

    /**
     *
     * @param unitsToAdd {int} negative if removing units, positive if adding units
     * @returns {int} return -1 if doesn't go over units
     */
    getUnitsOver = (unitsToAdd) => {
        let newUnits = this.units + unitsToAdd;
        const difference =  newUnits - this.preferredUnits;
        if(difference > 0){
            return difference;
        }
        else return -1;
    }

    /**
     *
     * @param courseId {int}
     * @param groupOfPreReqIds {int[]}
     */
    addGroupOfPreReqs = (courseId, groupOfPreReqIds) => {
        this.groupsOfPreReqIdsAndCourseId.push({courseId, groupOfPreReqIds});
    }

    /**
     *
     * @param courseId {int}
     * @param groupsOfUnmetPreReqIds {int[][]}
     */
    addPreReqWarnings = (courseId, groupsOfUnmetPreReqIds) => {
        groupsOfUnmetPreReqIds.forEach(groupOfUnmetPreReqIds => this.warnings.push(new WarningClass(WarningType.PRE_REQS_NOT_MET, courseId, this.id, groupOfUnmetPreReqIds)));
    }

    /**
     *
     * @param courseId {int} the course to be tested if it was required by this study-period
     */
    addPreReqWarningsFromThisPreReqs = (courseId) => {
        this.groupsOfPreReqIdsAndCourseId.forEach(groupOfPreReqIdsAndCourseId => {
            if(groupOfPreReqIdsAndCourseId.groupOfPreReqIds.some((preReqId) => preReqId === courseId)){
                this.warnings.push(new WarningClass(WarningType.PRE_REQS_NOT_MET, groupOfPreReqIdsAndCourseId.courseId, this.id, groupOfPreReqIdsAndCourseId.groupOfPreReqIds));
            }

        })
    }

    /**
     *  used if course is being removed from this study-period
     * @param courseId {int}
     */
    removePreReqsForCourse = (courseId) => {
        this.groupsOfPreReqIdsAndCourseId = this.groupsOfPreReqIdsAndCourseId.filter(groupOfPreReqIdsAndCourseId => groupOfPreReqIdsAndCourseId.courseId !== courseId);
    }

    /**
     * used if course is being removed from this study-period
     * @param courseId {int} course that is being remove from this study period
     */
    removePreReqWarningsForCourseThatIsBeingRemovedFromThisStudyPeriod = (courseId) => {
        this.warnings = this.warnings.filter(warning => {
            return !(warning.type === WarningType.PRE_REQS_NOT_MET && warning.courseId === courseId);
        });
    }

    /**
     * used if another course is being added to a study-period prior to this one or being set to complete
     * @param courseId {int} course that is being set to complete
     */
    removePreReqWarningsThatRequiredThisCourse = (courseId) => {
        this.warnings = this.warnings.filter(warning => {
            return !(warning.groupOfPreReqIds !== undefined && warning.groupOfPreReqIds.some(preReqId => preReqId === courseId))
        });
    }
}

export default StudyPeriodClass;