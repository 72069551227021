import React, {useState, useEffect} from 'react';
import PropType from 'prop-types';
import AdminCourse from './AdminCourse';
import DropBox, {DropBoxState} from "../../Student/DropBox";

const CourseTypeColumn = ({id, minimumUnits, courses, type, courseType, dispatch, dragStarted, error, selection}) => {

    return (
        <div
            key={id}
            className={''}
            onDragStart={(e) => e.stopPropagation()}
        >
            <div>
                <p className={type === 'degree' ? 'text-uon-gray font-sans font-bold text-md underline' : 'text-uon-blue-500 font-bold font-sans text-md'}>{courseType}</p>
                <p className={'font-sans text-uon-gray text-md'}>{courses.length > 0 ? parseInt(courses.reduce((previousValue, currentValue) => ({units: parseInt(previousValue.units) + parseInt(currentValue.units)})).units) : 0} units/{minimumUnits} units</p>
            </div>
            <div
                onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const id = parseInt(e.dataTransfer.getData('courseId'));

                    if(e.dataTransfer.getData('location') !== courseType && courses.findIndex(course => course.courseId === id) === -1)
                        e.dataTransfer.dropEffect = 'copy';
                    else e.dataTransfer.dropEffect = 'none';
                }}
                onDrop={(e) => {
                    e.stopPropagation();
                    const courseId = parseInt(e.dataTransfer.getData('courseId'));
                    //post request
                    dispatch({type: 'ADD', courseId, columnId: id});
                    const addToDegree = async () => {
                        const res = await fetch('http://localhost:4000/degreeCourse', {
                            method: "post",
                            headers: {
                                'Content-Type': 'application/json',
                                'Connection': 'keep-alive'
                            },

                            body: JSON.stringify({"degreeId": parseInt(selection), "courseId": courseId, "courseType": courseType})
                        })
                        if(res.status !== 201) throw new Error();
                    }
                    const addToOption = async () => {
                        const res = await fetch('http://localhost:4000/optionCourse', {
                            method: "post",
                            headers: {
                                'Content-Type': 'application/json',
                                'Connection': 'keep-alive'
                            },

                            body: JSON.stringify({"optionId": parseInt(selection), "courseId": courseId, "courseType": courseType})
                        })
                        if(res.status !== 201) throw new Error();
                    }
                    if(type === 'degree'){
                        addToDegree();
                    }
                    else if(type === 'option'){
                        addToOption();
                    }

                    //if the post request ends in an error or the response has a status code other then 200 it should cause an error prompt and the state should be reset
                }}
            >
                {dragStarted ?  (error ? <DropBox state={DropBoxState.ERROR} message={error}/> : <DropBox state={DropBoxState.DROP} />) : <DropBox state={DropBoxState.DEFAULT}/>}
            </div>
            <div
                className={'h-96 overflow-y-auto scroll-smooth '}
                onDrop={(e) => {
                    e.stopPropagation();
                }}
                onDragOver={(e) => {
                    e.stopPropagation();
                }}
            >
                {courses.map(course => <AdminCourse code={course.courseCode} id={course.courseId} name={course.courseName} location={id}/>)}
            </div>
        </div>
    );
}

CourseTypeColumn.propTypes = {
    requiredUnit: PropType.shape({
        type: PropType.string,
        courseType: PropType.string,
        minimumUnits: PropType.string
    }),
}

export default CourseTypeColumn;