import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StudentApp from './pages/StudentApp';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import PrivateRoute from './components/PrivateRoute';
import AdminApp from './pages/Admin/AdminApp';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<StudentApp />} />
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />

          <Route path='/admin' element={<PrivateRoute />}>
            <Route path='/admin' element={<AdminApp />} />
          </Route>

          {/* test */}
        </Routes>
      </Router>

      <ToastContainer />
    </>
  );
}

export default App;
