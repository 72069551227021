import { useContext } from 'react';
import StudentContext from '../../context/StudentContext';
import { FaEllipsisH } from 'react-icons/fa';

const YearMenu = ({ yearNo }) => {
  const { state, dispatch } = useContext(StudentContext);

  return (
    <div className='dropdown'>
      <label tabIndex={0}>
        <FaEllipsisH className='text-secondary hover:cursor-pointer' />
      </label>
      <ul
        tabIndex={0}
        className='dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 h-fit flex flex-col gap-y-1'
      >
        {/* Year heading */}
        <h1
          className={
            'flex flex-row text-uon-blue-700 font-sans font-bold text-2xl justify-center'
          }
        >
          Year {yearNo}
        </h1>

        {/* Study Periods */}
        <div>
          <h2 className={'mb-1 text-uon-gray text-lg font-semibold font-sans'}>
            Study Periods{' '}
          </h2>

          <div className='flex flex-col gap-y-0.5'>
            {state.getAvailableStudyPeriods().map((availableStudyPeriod) => {
              return (
                <div className='form-control w-full'>
                  <label
                    className='label cursor-pointer p-0 ml-2'
                    htmlFor={availableStudyPeriod}
                  >
                    <span className='label-text text-base'>
                      {availableStudyPeriod}
                    </span>
                    <input
                      type='checkbox'
                      className='checkbox checkbox-primary'
                      key={availableStudyPeriod}
                      name={availableStudyPeriod}
                      checked={state.isStudyPeriodActiveInYear(
                        yearNo,
                        availableStudyPeriod
                      )}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          dispatch({
                            type: 'REMOVE_STUDY_PERIOD',
                            year: yearNo,
                            studyPeriod: availableStudyPeriod,
                          });
                        } else
                          dispatch({
                            type: 'ADD_STUDY_PERIOD',
                            year: yearNo,
                            studyPeriod: availableStudyPeriod,
                          });
                      }}
                    />
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <button
          className='mt-2 h-8 w-full font-sans text-md text-white bg-red-500 hover:bg-red-400 rounded-2xl '
          onClick={() => {
            dispatch({ type: 'REMOVE_YEAR', year: yearNo });
          }}
        >
          Delete Year
        </button>
      </ul>
    </div>
  );
};

export default YearMenu;
