const init = (initialState) => {

    return [];

}

const courseTypeColumnReducer = (state, action) => {

    const newState = structuredClone(state);
    const columnId = action.columnId;
    const column = newState.find(column => column.id === columnId);
    const courseId = action.courseId;

    switch(action.type){
        case 'UPDATE':
            return action.data;

        case 'ADD':
            column.courses.push(courseId);
            return newState;

        case 'REMOVE':
            column.courses = column.courses.filter(course => course !== courseId);
            return newState;

        case 'ERROR':
            return newState.map(column => ({
                id: column.id,
                type: column.type,
                courseType: column.courseType,
                error: action.error,
                courses: column.courses,
                minimumUnits: column.minimumUnits,
                selectionId: column.selectionId
            }));

    }

}

export {courseTypeColumnReducer as default, init}