import React, {useContext} from 'react'
import StudentContext from "../../context/StudentContext";



const Detail = ({ course, courseCodeOnClick}) => {
    const {state, availabilities} = useContext(StudentContext);
  return (
    <div>
      <div className=' text-3xl text-center'>{course.courseCode}</div>

      <div className='mb-5 text-xs text-center'>{course.courseName}</div>

      <hr />

      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <hr />
        <div className='collapse-title font-bold text-sm'>
          Course description
        </div>
        <ul className='collapse-content flex flex-col gap-y-2 text-sm text-center'>
          <div className='flex flex-row gap-2'>{course.description}</div>
        </ul>
      </div>

      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <hr />
        <div className='collapse-title font-bold text-sm  text-start'>
          Course required
        </div>
        <ul className='collapse-content flex flex-col gap-y-2'>
          <div className='text-sm text-left'>
              <h2 className={'text-sm font-sans text-uon-gray font-normal'}>{course.groupsOfRequiredCourseIds.length > 0 ? 'You must complete at least one course from each group:':
                  `${course.courseCode} has no pre-requisites.`}</h2>
              {
              course.groupsOfRequiredCourseIds.map((groupOfRequiredCourseIds, index) => {

              return (
                  <>
                      <div className={'flex flex-col p-2.5 gap-y-2.5'}>
                          <h3 className={'text-sm font-sans text-uon-blue-700 font-bold'}>GROUP: {index + 1}</h3>
                          <div className={'flex flex-row flex-wrap gap-2.5 justify-center'}>
                              {groupOfRequiredCourseIds.map(requiredCourseId => {
                                  return state.getCourse(requiredCourseId).mapToComponent(undefined, courseCodeOnClick);
                              })}
                          </div>
                      </div>
                  </>
              )

          })}
          </div>
        </ul>
      </div>

      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <hr />
        <div className='collapse-title font-bold text-sm  text-start'>
          Similar Courses
        </div>
        <ul className='collapse-content flex flex-col gap-y-2'>
            <div className='text-sm text-left'>
                <h2 className={'text-sm font-sans text-uon-gray font-normal'}>{course.similarCourseIds.length === 0 ? `${course.courseCode} has no similar courses.` : ''}</h2>
                <div className={'flex flex-row flex-wrap gap-2.5 justify-center'}>
                    {course.similarCourseIds.map(similarCourseId => {
                        return state.getCourse(similarCourseId).mapToComponent(undefined, courseCodeOnClick);
                    })}
                </div>
            </div>
        </ul>
      </div>

      <div className='collapse-title font-bold text-sm text-start'>
        <p>
          Type: <span className='text-uon-blue-500'>{course.courseType}</span>
        </p>
        <p>
          Units: <span className='text-uon-blue-500'>{course.units}</span>
        </p>
        <p>
          Level: <span className='text-uon-blue-500'>{course.level}</span>
        </p>
      </div>
      <hr />

      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <hr />
        <div className='collapse-title text-sm font-bold text-start'>
          Course available
        </div>
        <ul className='collapse-content flex flex-col gap-y-2'>
          <div className='text-sm text-center'>{course.getAvailabilities(availabilities).map(availability =>
              {
                  return (
                      <div>
                          <h3 key={availability.location} className={'font-bold font-sans text-sm text-uon-blue-700'}>{availability.location}</h3>
                          <ul>
                          {availability.studyPeriods.map(studyPeriod => {
                              return (
                                  <li key={studyPeriod}>{studyPeriod}</li>
                              );
                          })}
                          </ul>
                      </div>
                  );
              }
          )}</div>
        </ul>
      </div>
    </div>
  );
};

export default Detail;
