import React, { useState, useContext } from 'react';
import { IconContext } from 'react-icons';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import Detail from './Detail';
import StudentContext from "../../context/StudentContext";

// import fetch from "node-fetch";

const Course = ({
  id,
  name,
  code,
  type,
  courseCodeOnClick,
  studyPeriodId,
  isCompleted,
}) => {

  const [hover, setHover] = useState(false);

  const {dispatch} = useContext(StudentContext);

  let middleText;

  if (hover) {
    if (!isCompleted)
      middleText = (
        <IconContext.Provider value={{ size: '2em' }}>
          <HiCheckCircle
            className={'text-green-500 cursor-pointer'}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_COMPLETED_REFACTOR',
                courseId: id,
                studyPeriodId: studyPeriodId,
              })
            }
          />
        </IconContext.Provider>
      );
    else
      middleText = (
        <IconContext.Provider value={{ size: '2em' }}>
          <HiXCircle
            className={'text-red-500 cursor-pointer'}
            onClick={() =>
              dispatch({
                type: 'TOGGLE_COMPLETED_REFACTOR',
                courseId: id,
                studyPeriodId: studyPeriodId,
              })
            }
          />
        </IconContext.Provider>
      );
  } else {
    middleText = name;
  }
  //id='courseCode' onClick={openCoInfo.bind(this, id)}
  return (
    <div
      // ref={drag}
      key={id}
      draggable={true}
      onDragStart={(e) => {
        e.dataTransfer.setData('courseId', id);
        e.dataTransfer.setData('studyPeriodId', studyPeriodId);
      }}
      className='relative w-28 h-28 flex-row items-center border border-solid py-0.5 gap-y-0.5 gap-1.5 rounded-md cursor-move
       bg-white border-secondary hover:bg-blue-100 hover:border-uon-blue-800 select-none snap-start'
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className='w-28'>
        <p
            id={'courseCode'}
            onClick={courseCodeOnClick}
          className='text-center text-base font-sans font-bold uppercase cursor-pointer
          text-uon-blue-500 hover:text-uon-blue-600 hover:underline hover:decoration-2'
        >
          {code}
        </p>
      </div>

      <div className='flex w-28 h-14 px-1 justify-center items-center'>
        <p
          className='text-center text-xs font-sans text-capitalize leading-normal line-clamp-3
          text-secondary'
        >
          {middleText}
        </p>
      </div>

      <div className='absolute w-28 bottom-0'>
        <p
          className='text-center text-xs font-sans uppercase leading-normal font-extrabold
          text-secondary'
        >
          {type}
        </p>
      </div>
    </div>
  );
};

export default Course;
