import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

const CreateOption = (getOptions) => {
  const [selectedDegree, setSelectedDegree] = useState(null);
  const [programs, setPrograms] = useState([]);

  function mod(modVal, message) {
    return this.test('mod', message, function (val) {
      const { path, createError } = this;
      if (val % modVal !== 0)
        return createError({
          path,
          message: message ?? `Must be a multiple of ${modVal}`,
        });
      return true;
    });
  }

  Yup.addMethod(Yup.number, 'mod', mod);

  const formik = useFormik({
    initialValues: {
      optionName: '',
      compulsoryUnits: '',
      directedUnits: '',
      electiveUnits: '',
    },

    validationSchema: Yup.object({
      optionName: Yup.string()
        .max(40, 'Must be 40 characters or less')
        .required('Required'),
      compulsoryUnits: Yup.number()
        .max(990, 'Too many units')
        .integer('Must be a whole number')
        .moreThan(-1, 'Must be 0 or greater')
        .required('Required')
        .mod(10),
      directedUnits: Yup.number()
        .max(90, 'Too many units')
        .integer('Must be a whole number')
        .moreThan(-1, 'Must be 0 or greater')
        .required('Required')
        .mod(10),
      electiveUnits: Yup.number()
        .max(90, 'Too many units')
        .integer('Must be a whole number')
        .moreThan(-1, 'Must be 0 or greater')
        .required('Required')
        .mod(10),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      console.log(selectedDegree.value);

      const createOption = async () => {
        await fetch('http://localhost:4000/option', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            optionName: values.optionName,
            degreeId: selectedDegree.value,
            units: [
              { type: 'COMPULSORY', value: parseInt(values.compulsoryUnits) },
              { type: 'DIRECTED', value: parseInt(values.directedUnits) },
              { type: 'ELECTIVE', value: parseInt(values.electiveUnits) },
            ],
          }),
        });
      };

      createOption();
      resetForm({ values: '' });
      getOptions();
    },
  });

  useEffect(() => {
    fetch('http://localhost:4000/programs')
      .then((response) => response.json())
      .then((data) => setPrograms(data));
  }, []);


  return (
    <form
      className='flex flex-col w-full gap-2'
      autoComplete={false}
      onSubmit={formik.handleSubmit}
    >
      {/* Modal Header */}
      <div className='flex bg-uon-blue-500 text-white text-3xl mb-2 py-1'>
        <div className='absolute top-0 left-0 bg-uon-blue-500 text-white w-full py-1'>
          <label className='ml-1.5 select-none'>CREATE NEW OPTION</label>
        </div>
        <label
          htmlFor='create-option-modal'
          className='btn btn-sm absolute right-0 top-1 bg-transparent border-none text-2xl hover:bg-transparent hover:text-white'
        >
          ✕
        </label>
      </div>

      {/* Option Name */}
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>Option name*</span>
        </label>
        <input
          type='text'
          id='optionName'
          name='optionName'
          placeholder='Option Name'
          className='input input-bordered w-full'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.optionName}
        />
        {formik.touched.optionName && formik.errors.optionName ? (
          <p className='text-error'>{formik.errors.optionName}</p>
        ) : null}
      </div>

      {/* From degree */}
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>From degree</span>
        </label>

        <Select
          name={'select-degree'}
          id='select-degree'
          placeholder={'Select your degree...'}
          isClearable={true}
          value={selectedDegree}
          onChange={(value, { action }) => {
            if (action === 'select-option') setSelectedDegree(value);
          }}
          options={programs.map((program) => ({
            value: program.degreeId,
            label: program.degreeName,
          }))}
          className={'w-full cursor-default'}
          isSearchable={true}
        />
      </div>

      <label htmlFor='' className='text-sm'>
        Required units for course types
      </label>

      {/* COMPULSORY */}
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>Compulsory</span>
        </label>
        <input
          type='text'
          id='compulsoryUnits'
          name='compulsoryUnits'
          placeholder='Enter units'
          className='input input-bordered w-full'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.compulsoryUnits}
        />
        {formik.touched.compulsoryUnits && formik.errors.compulsoryUnits ? (
          <p className='text-error'>{formik.errors.compulsoryUnits}</p>
        ) : null}
      </div>

      {/* DIRECTED */}
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>Directed</span>
        </label>
        <input
          type='text'
          id='directedUnits'
          name='directedUnits'
          placeholder='Enter units'
          className='input input-bordered w-full'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.directedUnits}
        />
        {formik.touched.directedUnits && formik.errors.directedUnits ? (
          <p className='text-error'>{formik.errors.directedUnits}</p>
        ) : null}
      </div>

      {/* ELECTIVE */}
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>Elective</span>
        </label>
        <input
          type='text'
          id='electiveUnits'
          name='electiveUnits'
          placeholder='Enter units'
          className='input input-bordered w-full'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.electiveUnits}
        />
        {formik.touched.electiveUnits && formik.errors.electiveUnits ? (
          <p className='text-error'>{formik.errors.electiveUnits}</p>
        ) : null}
      </div>

      <div className='flex flex-row gap-2'>
        <label className='btn btn-outline' htmlFor='create-option-modal'>
          Cancel
        </label>
        <button type='submit' className='btn btn-primary text-white'>
          Submit
        </button>
      </div>
    </form>
  );
};

export default CreateOption;
