import WarningClass, {WarningType} from '../classes/WarningClass'
import Year from "../components/Student/Year";
import YearClass from '../classes/YearClass'
import StudyPeriodClass from "./StudyPeriodClass";

class StateClass {
    /**
     * @type {CourseClass[]}
     */
    courses;
    /**
     * @type {StudyPeriodClass[]}
     */
    activeStudyPeriods;

    /**
     * @type {int[]}
     */
    completedCourseIds;

    /**
     * @type {{availabilityId: string, location: string, studyPeriod: string}[]}
     */
    defaultStudyPeriods;

    /**
     * @type {string}
     */
    location;

    /**
     * @type {int}
     */
    numberOfYears;

    /**
     *
     * @param courses {CourseClass[]}
     * @param activeStudyPeriods {StudyPeriodClass[]}
     * @param completedCourseIds {int[]}
     * @param defaultStudyPeriods {{availabilityId: string, location: string, studyPeriod: string}[]}
     * @param location {string}
     * @param numberOfYears {int}
     * @param availabilities {{availabilityId: string, location: string, studyPeriod: string}[]}
     * @param defaultUnits {int}
     */
    constructor(
        courses,
        availabilities,
        activeStudyPeriods,
        completedCourseIds,
        defaultStudyPeriods,
        location,
        numberOfYears,
        defaultUnits
    ) {
        this.courses = courses;
        this.numberOfYears = numberOfYears;
        this.activeStudyPeriods = activeStudyPeriods;
        this.completedCourseIds = completedCourseIds;
        this.location = location;
        this.defaultStudyPeriods = defaultStudyPeriods;
        this.availabilities = availabilities;
        this.defaultUnits = defaultUnits;
    }

    /**
     * @param courseId {int}
     * @param {int} [beginIndex]
     * @param {int} [endIndex]
     * @returns {boolean}
     */
    isCourseInAStudyPeriod(courseId, beginIndex = 0, endIndex = -1) {
        //course is already in a study-period
        let finish = this.activeStudyPeriods.length;
        if (endIndex !== -1) finish = endIndex;
        if (this.activeStudyPeriods.length < finish) throw new Error('array length error');
        for (let i = beginIndex; i < finish; i++) {
            if (this.activeStudyPeriods[i].isCourseInStudyPeriod(courseId)) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param courseId {int}
     */
    setErrors(courseId) {
        this.activeStudyPeriods.forEach(activeStudyPeriod => activeStudyPeriod.setError(this.getCourse(courseId), this));
    }

    removeErrors() {
        this.activeStudyPeriods.forEach(activeStudyPeriod => activeStudyPeriod.removeError());
    }

    /**
     * updates the locations for the default study-periods and active study-periods.
     * @param location
     */
    set defaultLocation(location){
        //update default study-periods
        if(this.defaultStudyPeriods) {
            this.defaultStudyPeriods = this.defaultStudyPeriods
                .filter(defaultStudyPeriod => this.availabilities.some(availability => availability.location === location && availability.studyPeriod === defaultStudyPeriod.studyPeriod))
                .map(defaultStudyPeriod => this.availabilities.find(availability => availability.location === location && availability.studyPeriod === defaultStudyPeriod.studyPeriod));
        }
        //update active study-periods -> this should also remove courses that are no longer available in the study periods
        for(let i = 0; i < this.activeStudyPeriods.length; i++){
            const studyPeriod = this.availabilities.find(availability => availability.availabilityId === this.activeStudyPeriods[i].availabilityId).studyPeriod;
            const availability = this.availabilities.find(availability => availability.studyPeriod === studyPeriod && availability.location === location);
            if(!availability) this.removeStudyPeriod(this.activeStudyPeriods[i].id, this.activeStudyPeriods[i].year);
            else {
                this.activeStudyPeriods[i].setAvailabilityId(availability.availabilityId, this);
            }
        }
        //update location
        this.location = location;
    }

    get defaultLocation(){
        return this.location;
    }

    /**
     * sets the default study-periods
     * @param studyPeriods {{availabilityId: string, location: string, studyPeriod: string}[]}
     */
    setDefaultStudyPeriods(studyPeriods) {
        this.defaultStudyPeriods = studyPeriods;
        // if (studyPeriods.some(studyPeriod => studyPeriod.location !== this.location)) throw new Error();
        // this.activeStudyPeriods.forEach(activeStudyPeriod => {
        //     if (studyPeriods.some(studyPeriod => studyPeriod.availabilityId !== activeStudyPeriod.availabilityId)) {
        //         this.removeStudyPeriod(activeStudyPeriod.id, activeStudyPeriod.year);
        //     }
        // });
        // for(let i = 1; i < this.activeStudyPeriods; ){
        //     if(this.activeStudyPeriods.some(activeStudyPeriod => activeStudyPeriod.year !== i)) break;
        //     const availabilitiesForYear = this.activeStudyPeriods
        //         .filter(activeStudyPeriod => activeStudyPeriod.year === i)
        //         .map(activeStudyPeriod => this.availabilities.find(availability => activeStudyPeriod.availabilityId === availability.availabilityId));
        //     if(availabilitiesForYear.length < studyPeriods.length){
        //         studyPeriods.forEach(studyPeriod => {
        //             if(availabilitiesForYear.some(availabilitiesForYear => availabilitiesForYear.availabilityId !== studyPeriod.availabilityId)){
        //                 //ADD STUDY PERIOD TO YEAR
        //                 //this.addStudyPeriod(studyPeriod, year);
        //             }
        //         })
        //     }
        // }
    }

    /**
     * removes the studyPeriod that corresponds to a studyPeriodId, if the studyPeriod is the last one in its year then
     * the enclosing year will also be removed
     * @param studyPeriodId {int}
     * @param year {int}
     */
    removeStudyPeriod(studyPeriodId, year) {
        const studyPeriod = this.activeStudyPeriods.find(activeStudyPeriod => activeStudyPeriod.id === studyPeriodId);
        studyPeriod.courseIds.forEach(courseId => {
            this.removeCourseFromStudyPeriod(courseId, this.getCourse(courseId).units, studyPeriod);
        });
        //if active studyPeriod only has one studyPeriod left in it, need to do remove the year
        const studyPeriodsForYear = this.activeStudyPeriods.filter(activeStudyPeriod => activeStudyPeriod.year === year);
        if (studyPeriodsForYear.length === 1) {
            this.activeStudyPeriods = this.activeStudyPeriods.filter(activeStudyPeriod => activeStudyPeriod.id !== studyPeriodId)
                .map(activeStudyPeriod => {
                    if (activeStudyPeriod.id > studyPeriodId && activeStudyPeriod.year > year) {
                        return new StudyPeriodClass(activeStudyPeriod.id - 1, activeStudyPeriod.year - 1, activeStudyPeriod.availabilityId,
                            activeStudyPeriod.courseIds, activeStudyPeriod.preferredUnits, activeStudyPeriod.units,
                            activeStudyPeriod.error, activeStudyPeriod.warnings, activeStudyPeriod.groupsOfPreReqIdsAndCourseId);
                    } else return activeStudyPeriod;
                });
        } else {
            this.activeStudyPeriods = this.activeStudyPeriods.filter(activeStudyPeriod => activeStudyPeriod.id !== studyPeriodId)
                .map(activeStudyPeriod => {
                    if (activeStudyPeriod.id > studyPeriodId) {
                        return new StudyPeriodClass(activeStudyPeriod.id - 1, activeStudyPeriod.year, activeStudyPeriod.availabilityId,
                            activeStudyPeriod.courseIds, activeStudyPeriod.preferredUnits, activeStudyPeriod.units,
                            activeStudyPeriod.error, activeStudyPeriod.warnings, activeStudyPeriod.groupsOfPreReqIdsAndCourseId);
                    } else return activeStudyPeriod;
                });
        }
    }

    removeYear(yearNo) {
        if(this.numberOfYears === 0) throw new Error();
        this.numberOfYears--;
        //remove all studyPeriods with yearNo
        const activeStudyPeriodsForYears = this.activeStudyPeriods.filter(activeStudyPeriod => activeStudyPeriod.year === yearNo);
        for (let i = 0; i < activeStudyPeriodsForYears.length; i++) {
            this.removeStudyPeriod(activeStudyPeriodsForYears[0].id, yearNo);
        }
    }

    setYears(numberOfYears){
        if(numberOfYears <= 0) throw new Error();
        //add or remove years depending on this.numberOfYears and numberOfYears
        const difference = numberOfYears - this.numberOfYears;
        if(difference < 0){
            for(let i = 0; i < (difference * -1); i++){
                this.removeYear(this.numberOfYears - i);
            }
        }
        else if(difference > 0){
            for(let i = 0; i < difference; i++){
                this.addYear();
            }
        }
        this.numberOfYears = numberOfYears;
    }

    /**
     *
     */
    addYear(){
        this.numberOfYears++;
        this.defaultStudyPeriods.forEach(studyPeriod => {
            this.addStudyPeriodToYear(studyPeriod.studyPeriod, this.numberOfYears);
        })
    }

    /**
     * adds a study period to a year
     * @param studyPeriod {string}
     * @param year {int}
     */
    addStudyPeriodToYear(studyPeriod, year){

        console.log(studyPeriod);
        console.log(this.activeStudyPeriods.map(activeStudyPeriod => ({id: activeStudyPeriod.id, year:activeStudyPeriod.year})));
        if(year > this.numberOfYears || year <= 0) throw new Error();
        const availability = this.availabilities.find(availability => availability.studyPeriod === studyPeriod && availability.location === this.location);
        const index = this.availabilities.findIndex(availability => availability.studyPeriod === studyPeriod && availability.location === this.location);
        let spsForYear = this.activeStudyPeriods //{index: position in availability table in the database, data: studyPeriod }
            .filter(activeStudyPeriod => activeStudyPeriod.year === year)
            .map(activeStudyPeriod => ({index: this.availabilities.findIndex(availability => availability.availabilityId === activeStudyPeriod.availabilityId), data: activeStudyPeriod}));
        const insertStudyPeriod = (id) => {
            this.activeStudyPeriods = this.activeStudyPeriods.map(activeStudyPeriod => {
                if (activeStudyPeriod.id >= id) {
                    return new StudyPeriodClass(id + 1, activeStudyPeriod.year, activeStudyPeriod.availabilityId, activeStudyPeriod.courseIds, activeStudyPeriod.preferredUnits, activeStudyPeriod.units, activeStudyPeriod.error, activeStudyPeriod.warnings, activeStudyPeriod.groupsOfPreReqIdsAndCourseId);
                }
                else return activeStudyPeriod;
            });
            this.activeStudyPeriods.push(new StudyPeriodClass(id, year, availability.availabilityId, [], this.defaultUnits, 0, null, [], []));
            this.activeStudyPeriods = this.activeStudyPeriods.sort((a, b) => {
                return a.id - b.id;
            })
        }
        if(spsForYear.length === 0){
            if(year !== this.numberOfYears) throw new Error();
            this.activeStudyPeriods.push(new StudyPeriodClass(this.activeStudyPeriods.length, year, availability.availabilityId, [], this.defaultUnits, 0, null, [], []));
            console.log(studyPeriod);
            console.log(this.activeStudyPeriods.map(activeStudyPeriod => ({id: activeStudyPeriod.id, year:activeStudyPeriod.year})));
        }
        else {
            console.log('sps');
            console.log(spsForYear);
            spsForYear = spsForYear.sort((a, b) => {
                return a.index - b.index;
            })
            const nextStudyPeriod = spsForYear.find(sp => sp.index > index);
            if(nextStudyPeriod) {
                insertStudyPeriod(nextStudyPeriod.id);
            }
            else {
                insertStudyPeriod(spsForYear[spsForYear.length - 1].data.id + 1);
            }
            console.log(studyPeriod);
            console.log(this.activeStudyPeriods.map(activeStudyPeriod => ({id: activeStudyPeriod.id, year:activeStudyPeriod.year})));
        }
    }

    /**
     *
     * @param course {CourseClass}
     * @returns {boolean}
     */
    similarCourseInAStudyPeriod(course) {
        return course.similarCourseIds.some(similarCourseId => this.isCourseInAStudyPeriod(similarCourseId));
    }

    /**
     *
     * @param course {CourseClass}
     * @returns {boolean}
     */
    similarCourseIsComplete(course) {
        return course.similarCourseIds.some(similarCourseId => this.isCourseCompleted(similarCourseId));
    }

    /**
     *
     * @param course {CourseClass} the course you want to check if pre-reqs are met for
     * @param studyPeriod  {StudyPeriodClass} the study period you are trying to add the course to
     * @returns {int[][]}  array of the groups of pre-reqs that are not met
     */
    getGroupsOfUnmetPreReqs(course, studyPeriod) {
        const groupsOfUnmetPreReqIds = [];
        course.groupsOfRequiredCourseIds.forEach(groupOfRequiredCourseIds => {
            if (groupOfRequiredCourseIds.every(requiredCourseId => !this.isCourseInAStudyPeriod(requiredCourseId, 0, studyPeriod.id) && !this.isCourseCompleted(requiredCourseId))) {
                groupsOfUnmetPreReqIds.push(groupOfRequiredCourseIds);
            }
        })
        return groupsOfUnmetPreReqIds;
    }

    /**
     *
     * @returns {string[]}
     */
    getAvailableStudyPeriods() {
        return this.availabilities.filter(availability => availability.location === this.location).map(availability => availability.studyPeriod);
    }

    /**
     *
     * @param year {int}
     * @param studyPeriod {string}
     * @returns {boolean}
     */
    isStudyPeriodActiveInYear(year, studyPeriod) {
        const availabilityId = this.availabilities.find(availability => availability.studyPeriod === studyPeriod && availability.location === this.location).availabilityId;
        //console.log(availabilityId);
        return this.activeStudyPeriods.findIndex(activeStudyPeriod => {
            //console.log(activeStudyPeriod.id);
            //console.log(activeStudyPeriod.year);
            return activeStudyPeriod.year === year && activeStudyPeriod.availabilityId === availabilityId;
        }) !== -1;
    }

    /**
     *
     * @param courseId {int}
     * @returns {CourseClass[]}
     */
    getSimilarCourses(courseId) {
        const similarCourses = [];
        const course = this.courses.find(course => course.courseId === courseId);
        if (!course) throw new Error();
        course.similarCourseIds.forEach(similarCourseId => {
            const similarCourse = this.courses.find(course => course.courseId === similarCourseId);
            similarCourses.push(similarCourse);
        })
        return similarCourses;
    }

    /**
     *
     * @param courseId {int} course that is getting set to complete
     * @param {int} [beginIndex]
     * @param {int} [endIndex]
     */
    removePreRequisiteWarningsThatRequiredThisCourse(courseId, beginIndex = 0, endIndex = -1){

        //studyPeriod.removePreReqsForCourse(courseId);

        let end = (endIndex < this.activeStudyPeriods.length && endIndex !== -1) ? endIndex + 1 : this.activeStudyPeriods.length;
        for (let i = beginIndex; i < end; i++) {
            this.activeStudyPeriods[i].removePreReqWarningsThatRequiredThisCourse(courseId);
        }
    }

    /**
     * use this if you wish to add warnings by searching through pre-reqs array in the study-period
     * it doesn't fully check if pre-reqs are not met, i.e it won't check if another course that would satisfy the pre-req is in a previous study-period or set to complete. (this is bad but don't intend on fixing)
     * @param courseId {int}
     * @param {int} [beginIndex]
     * @param {int} [endIndex]
     */
    addPreRequisiteWarningsForStudyPeriodsThatRequireThisCourse = (courseId, beginIndex = 0, endIndex = -1) => {
        let end = (endIndex < this.activeStudyPeriods.length && endIndex !== -1) ? endIndex + 1 : this.activeStudyPeriods.length;
        for (let i = beginIndex; i < end; i++) {
            this.activeStudyPeriods[i].addPreReqWarningsFromThisPreReqs(courseId);
        }
    }

    /**
     *
     * @param courseId {int}
     * @returns {boolean}
     */
    isCourseCompleted(courseId) {
        return this.completedCourseIds.includes(courseId);
    }

    /**
     * removes the course from the study-period, and then updates warnings for all study-periods and removes the pre-reqs for the course from the studyPeriod
     * @param courseId course getting removed
     * @param units units of course getting removed
     * @param studyPeriod study-period that the course is getting removed from
     */
    removeCourseFromStudyPeriod(courseId, units, studyPeriod) {
        if (!studyPeriod.isCourseInStudyPeriod(courseId)) throw new Error('course should be in this study-period, but isn\'t');
        studyPeriod.courseIds = studyPeriod.courseIds.filter(courseId1 => courseId1 !== courseId);
        studyPeriod.removePreReqWarningsForCourseThatIsBeingRemovedFromThisStudyPeriod(courseId);
        studyPeriod.removePreReqsForCourse(courseId);
        studyPeriod.updateUnits(courseId, -units);
        this.addPreRequisiteWarningsForStudyPeriodsThatRequireThisCourse(courseId, studyPeriod.id);
    }

    /**
     * checks if warnings should be changed for any of the study periods in this.activeStudyPeriods, then updates the warnings if necessary
     * and adds the course to the study period that corresponds to the studyPeriodId param
     * @param course {CourseClass}
     * @param studyPeriod {StudyPeriodClass}
     * @return {boolean} return true if the course was successfully added to the studyPeriod
     */
    addCourseToStudyPeriod(course, studyPeriod) {
        if (
            !studyPeriod.isAvailableInStudyPeriod(course) ||
            this.similarCourseInAStudyPeriod(course) ||
            this.similarCourseIsComplete(course) ||
            this.isCourseInAStudyPeriod(course.courseId) ||
            this.isCourseCompleted(course.courseId)
        )
            return false;
        //warnings
        const groupsOfUnmetPreReqIds = this.getGroupsOfUnmetPreReqs(course, studyPeriod);
        //push warnings for unmet pre-reqs if there are any
        studyPeriod.updateUnits(course.courseId, course.units);
        //create function in state class
        studyPeriod.addPreReqWarnings(
            course.courseId,
            groupsOfUnmetPreReqIds
        );
        //add pre-requisites to study-period
        studyPeriod.addGroupsOfPreReqs(
            course.courseId,
            course.groupsOfRequiredCourseIds
        );
        //remove pre-requisite warnings
        this.removePreRequisiteWarningsThatRequiredThisCourse(
            course.courseId,
            studyPeriod.id + 1
        );
        //add course to current study-period in newState
        studyPeriod.courseIds.push(course.courseId);
        return true;
    }

    /**
     * @param studyPeriod {StudyPeriodClass} the studyPeriod you wish to add the course to
     * @param course {CourseClass} the course you are adding to the studyPeriod
     * @return {boolean} true if all groups of pre-reqs for a course have at least 1 pre-req met
     */
    preReqsAreMet(course, studyPeriod) {
        return course.groupsOfRequiredCourseIds.every(groupOfRequiredCourseIds =>
            // returns true if all pre-reqs in the group are not met
            !groupOfRequiredCourseIds.every(requiredCourseId => !this.isCourseInAStudyPeriod(requiredCourseId, 0, studyPeriod.id) && !this.isCourseCompleted(requiredCourseId))
        )
    }

    /**
     *
     * @param courseId {int}
     * @returns {CourseClass}
     */
    getCourse(courseId) {
        return this.courses.find(course => courseId === course.courseId);
    }

    /**
     *
     * @param course {CourseClass}
     */
    setCourseCompleted(course) {
        this.completedCourseIds.push(course.courseId);
        course.setCompleted();
    }

    /**
     *
     * @param course {CourseClass}
     */
    setCourseIncomplete(course) {
        this.completedCourseIds = this.completedCourseIds.filter(completedCourseId => completedCourseId !== course.courseId);
        course.setInCompleted();
    }

    //overall units completed for a course type

    /**
     *
     * @param courseType {string}
     * @return {int}
     */
    getUnitsCompletedForCourseType(courseType) {
        return this.courses.reduce((previousValue, currentValue) => {
            if (currentValue.hasOwnProperty('courseType')) {
                if (currentValue.courseType === courseType && (currentValue.completed || this.isCourseInAStudyPeriod(currentValue.courseId)))
                    return previousValue + currentValue.units;
                else return previousValue;
            } else throw new Error('a course in the courses array doesn\'t have a course type');
        }, 0)
    }

    getUnitsCompleted() {
        return this.courses.reduce((previousValue, currentValue) => {
            if (currentValue.completed || this.isCourseInAStudyPeriod(currentValue.courseId))
                return previousValue + currentValue.units;
            else return previousValue;
        }, 0)
    }

    /**
     * @return {WarningClass[]}
     */
    getWarnings() {
        return this.activeStudyPeriods.map(activeStudyPeriod => activeStudyPeriod.warnings).flat(1);
    }

    /**
     *
     * @param units {int}
     */
    setDefaultUnits(units) {
        this.defaultUnits = units;
    }

    /**
     *
     * @param years {int}
     */
    setNumberOfYears(years) {
        this.numberOfYears = years;
    }

    /**
     *
     * @param courses {CourseClass[]}
     * @return {int} the id for the first study period that this group of pre-reqs is satisfied for
     */
    getStudyPeriodIdThatContainsACourse(courses){
        this.activeStudyPeriods.findIndex(activeStudyPeriod => courses.some(course => activeStudyPeriod.courseIds.some(courseId => courseId === course.courseId)));
    }

    /**
     * adds one course from the groupOfPreReqs to a studyPeriod if the pre-reqs are met for one the courses and one of the courses with its pre-reqs met also
     * has a course type that isn't 'other' or 'elective' and it can be added to a studyPeriod using this.addCourseToStudyPeriod without going over the
     * preferred units for the study-period
     * @param groupOfPreReqs {CourseClass[]}
     * @param {StudyPeriodClass} [studyPeriod] if the groupOfPreReqs is for a course that is currently added to this studyPeriod
     * @return {boolean} true if a course was added to a studyPeriod to satisfy the pre-req group
     */
    satisfyGroupOfPreReqs(groupOfPreReqs, studyPeriod) {
        return groupOfPreReqs.some(course => {


            for(let i = 0; i < studyPeriod ? studyPeriod.id + 1 : this.activeStudyPeriods.length; i++) {
                if (
                    this.preReqsAreMet(course, this.activeStudyPeriods[i]) //pre-reqs are met in this study-period
                    &&
                    this.activeStudyPeriods[i].getUnitsOver(course.courseId) === -1 //study period doesn't go over units
                    &&
                    course.courseType !== 'ELECTIVE' //course type doesn't equal elective
                    &&
                    course.courseType !== 'OTHER' //course type doesn't equal other
                )
                {
                    return this.addCourseToStudyPeriod(course, this.activeStudyPeriods[i]);
                }
            }
        })
    }

    /**
     *
     * @param courses {CourseClass[]}
     * @return {CourseClass[]}
     */
    filterOutCompletedCourses(courses){
        return courses.filter(course => !this.isCourseInAStudyPeriod(course.courseId) || !this.isCourseCompleted(course.courseId)); //filter out courses that are already completed
    }

    /**
     *
     * @param location {string}
     */
    setLocation(location) {
        this.location = location;
    }



    //get array of courses that are not yet completed
    //get array of un-met pre-reqs
    //use array of minimum required units for course types to find how many units that still need to completed
    //order the not completed courses by the second part of the course code
    //loop through the activeStudyPeriods and add 1 course at a time

    //keep looping until study-period if full
    //loop through un-met pre-reqs

    //check its pre-reqs are met
    //check the study period won't go over units
    //check not over the minimum units for a specific course type if it is then remove this course from the not completed courses array
    //check it can be added to the study-period

    //add course to study-period
    //use function in state class
    //decrement the units left for this course type

    //if minimum units for all course-types are completed, then break
    //else if courses array is empty, can't autocomplete
    //else if this is the last study-period && the units are met (e.g. 30/30 units in the study-period already), then add 1 more year

    //keep looping until study-period is full
    //loop through not completed courses

    //check its pre-reqs are met
    //check the study period isn't over units
    //check not over the minimum units for a specific course type if it is then remove this course from the not completed courses array
    //check it can be added to the study-period

    //ADD COURSE TO STUDY-PERIOD
    //use function in state class
    //decrement the units left for this course type

    //if minimum units for all course-types are completed,then break
    //else if not-completed courses is empty, can't autocomplete
    //else if this is the last study-period && the units are met (e.g. 30/30 units in the study-period already), then add 1 more year
    /**
     *
     * @param requiredUnits {{type: string, courseType: string, minimumUnits: int}[]}
     */
    autocomplete(requiredUnits) {

        //un-met pre-reqs
        let groupsOfUnMetPreReqs = this.getWarnings()
            .filter(warning => warning.type !== WarningType.OVER_UNITS)
            .map(warning => ({
                groupOfPreReqs: warning.groupOfPreReqIds
                    .map(preReqId => this.getCourse(preReqId)) //map the courseId's to their corresponding course objects from the courses array
                    .filter(course => requiredUnits.some(requiredUnit => requiredUnit.courseType === course.courseType)) //filter out courses that are not apart of the user's selected program
                ,
                studyPeriodId: warning.studyPeriodId
            }));

        //not completed courses
        //need to filter out courses that don't have a course type that is in the requiredUnits array
        const inCompleteCourses = this.courses
            .filter(course => requiredUnits.some(requiredUnit => requiredUnit.courseType === course.courseType)) //filter out courses that aren't apart of the users selected program
            .filter(course => !this.isCourseInAStudyPeriod(course.courseId) || !this.isCourseCompleted(course.courseId)); //filter out courses that are already completed

        let sortedIncompleteCourses = inCompleteCourses.sort((c1, c2) => {
            let course1 = parseInt(c1.courseCode.substring(4, 8));
            let course2 = parseInt(c2.courseCode.substring(4, 8));

            if (course1 > course2) return 1;
            if (course1 < course2) return -1;
            return 0;
        });



        let unitsLeftToCompleteForCourseTypes = requiredUnits.map(requiredUnit => {
            return {
                courseType: requiredUnit.courseType,
                unitsLeft: requiredUnit.minimumUnits - this.getUnitsCompletedForCourseType(requiredUnit.courseType)
            };
        });

        for (let i = 0; i < this.activeStudyPeriods.length; i++) {

            //add pre-req course to study-period
            // groupsOfUnMetPreReqs = groupsOfUnMetPreReqs.filter(groupOfUnMetPreReqs => {
            //
            //     for (let j = 0; j < groupOfUnMetPreReqs.group.length; i++) {
            //         //IF pre-reqs are met AND study-period won't go over units AND it is is available in the study-period
            //         const course = groupOfUnMetPreReqs.group[j];
            //         const studyPeriod = this.activeStudyPeriods[i];
            //         if (i >= groupOfUnMetPreReqs.studyPeriodId) return true;
            //         if (
            //             this.preReqsAreMet(course, studyPeriod)
            //             &&
            //             studyPeriod.getUnitsOver(course.units) === -1
            //             &&
            //             studyPeriod.isAvailableInStudyPeriod(course)
            //             &&
            //             !this.isCourseInAStudyPeriod(course.courseId, 0)
            //         ) {
            //
            //             this.addCourseToStudyPeriod(course, studyPeriod);
            //             unitsLeftToCompleteForCourseTypes.map(unitsLeftToCompleteForCourseType => {
            //                 if (unitsLeftToCompleteForCourseType.courseType === course.courseType) {
            //                     return {
            //                         units: unitsLeftToCompleteForCourseType.unitsLeft - course.units,
            //                         courseType: unitsLeftToCompleteForCourseType.courseType
            //                     };
            //                 }
            //             });
            //
            //             return false;
            //         } else if (!this.preReqsAreMet(course, studyPeriod)) {
            //             groupOfUnMetPreReqs.push()
            //         }
            //
            //         // add the course to the study-period
            //         // decrement the units left for course type
            //         // return false to filter this group out of the array
            //     }
            //     return true;
            //     //else return true to keep this group in the array
            // });
            //check its pre-reqs are met <>
            //check the study period won't go over units <>
            //check not over the minimum units for a specific course type if it is then remove this course from the not completed courses array <>
            //check it can be added to the study-period  <>

            //add course to study-period
            //use function in state class
            //decrement the units left for this course type

            //if minimum units for all course-types are completed, then break
            //else if unMetPreReqs array is empty, can't autocomplete
            //else if this is the last study-period && the units are met (e.g. 30/30 units in the study-period already),
            //  if years won't be greater than maximum program duration
            //      add 1 more year

            console.log("sorted list of incomplete courses ", sortedIncompleteCourses);

            sortedIncompleteCourses = sortedIncompleteCourses.filter(course => {
                //console.log("course ", course);
                const studyPeriod = this.activeStudyPeriods[i];
                if(
                    this.preReqsAreMet(course, studyPeriod) //pre-reqs will be met for this course if it is added to the study period
                    &&
                    studyPeriod.getUnitsOver(course.units) === -1 //study period won't be over units if the course is added to it
                    &&
                    unitsLeftToCompleteForCourseTypes  //won't go over minimum units for the course type of the course
                        .some(unitsLeftToCompleteForCourseType =>
                            (unitsLeftToCompleteForCourseType.courseType === course.courseType)
                            &&
                            (unitsLeftToCompleteForCourseType.unitsLeft - course.units >= 0))
                    &&
                    (this.getUnitsCompleted() >= course.preRequisiteUnits)
                ){
                    if(this.addCourseToStudyPeriod(course, studyPeriod))
                    {
                        unitsLeftToCompleteForCourseTypes = unitsLeftToCompleteForCourseTypes
                            .map(unitsLeftToCompleteForCourseType => {
                                if(unitsLeftToCompleteForCourseType.courseType === course.courseType) {
                                    return {
                                        courseType: unitsLeftToCompleteForCourseType.courseType,
                                        unitsLeft: unitsLeftToCompleteForCourseType.unitsLeft - course.units
                                    }
                                }
                                else return unitsLeftToCompleteForCourseType;
                            })
                        return false;
                    }
                    else return true;
                }
                else return true;
            })

        }

    }

}

export default StateClass;