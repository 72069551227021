import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';

function ForgotPassword() {
  const [email, setEmail] = useState('');

  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success('Email was sent');
    } catch (error) {
      toast.error('Could not send reset email');
    }
  };

  return (
    <div className='h-screen flex flex-col items-center justify-center'>
      <header>
        <p className='text-2xl'>Forgot Password</p>
      </header>

      <main>
        <form onSubmit={onSubmit}>
          {/* Email div */}
          <label htmlFor='email' className='label'>
            <span className='label-text'>Email Address</span>
          </label>
          <input
            type='email'
            className='input input-bordered input-md w-full'
            placeholder='Email address'
            id='email'
            value={email}
            onChange={onChange}
          />
          <label htmlFor='password' className='label'>
            <span className='label-text-alt'>
              <Link className='text-cyan-400 hover:text-cyan-300' to='/sign-in'>
                Sign In
              </Link>
            </span>
          </label>
          <button className='btn btn-info text-white w-full'>
            Send Reset Link
          </button>
        </form>
      </main>
    </div>
  );
}

export default ForgotPassword;
