import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import DropBox, { DropBoxState } from './DropBox';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { IoWarning } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import { WarningType } from '../../classes/WarningClass';
import StudentContext from "../../context/StudentContext";

const StudyPeriod = ({
  children,
  units,
  name,
  id,
  warnings,
  error,
    setIsRemoving
}) => {
  const options = [
    { label: '0 units', value: 0 },
    { label: '10 units', value: 10 },
    { label: '20 units', value: 20 },
    { label: '30 units', value: 30 },
    { label: '40 units', value: 40 },
  ];

  const {state, dispatch} = useContext(StudentContext);

  const [errorState, setErrorState] = useState(DropBoxState.DEFAULT);

  useEffect(() => {
    if (!error) {
      setErrorState(DropBoxState.DEFAULT);
    } else if (
      error.type === WarningType.PRE_REQS_NOT_MET ||
      error.type === WarningType.OVER_UNITS
    ) {
      setErrorState(DropBoxState.WARNING);
    } else if (error.type === WarningType.NO_PROBLEMS) {
      setErrorState(DropBoxState.DROP);
    } else {
      setErrorState(DropBoxState.ERROR);
    }
  }, [error]);

  //event handler used when a course is dropped into a study-period
  /**
   * adds dropped course into the study-period if possible
   * @param e
   */
  const dropHandler = (e) => {
    e.stopPropagation();
    const courseId = parseInt(e.dataTransfer.getData('courseId'));
    if (errorState !== DropBoxState.ERROR) {
      dispatch({
        type: 'ADD_COURSE_AND_WARNINGS',
        courseId,
        studyPeriodId: id,
      });
    }
  };

  //event handler used when a course is dragged over this study-period
  /**
   * stops courses from being dropped in the drop-zone if they cause an error
   * @param e
   */
  const dragOverHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (errorState !== DropBoxState.ERROR) {
      e.dataTransfer.dropEffect = 'copy';
    } else e.dataTransfer.dropEffect = 'none';
  };

  const getMainStyle = () => {
    if (children.length <= 1)
      return 'flex flex-col border-secondary border-solid border rounded-md w-72 p-2.5 gap-2.5 bg-white h-fit';
    else
      return 'flex flex-col border-secondary border-solid border rounded-md p-2.5 gap-2.5 bg-white h-fit';
  };

  return (
    <div
      className={'flex flex-col border-secondary border-solid border rounded-md p-2.5 gap-2.5 bg-white h-fit'}
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
      key={id}
      onDragEnter={(e) => {
        e.stopPropagation();
        setIsRemoving(false);
      }}
    >
      <div
        className={
          'bg-white flex flex-row h-9 place-content-between items-center gap-x-12'
        }
      >
        <div className={'flex flex-row items-center'}>
          <h1 className={'text-secondary font-sans font-bold text-xl'}>
            {name || 'ERROR'}
          </h1>
          {warnings.length !== 0 ? (
            <>
              <div data-tip data-for={'warnings'}>
                <IconContext.Provider value={{ size: '1.2em' }}>
                  <IoWarning className={'text-yellow-400 cursor-pointer'} />
                </IconContext.Provider>
              </div>
              <ReactTooltip id={'warnings'} type={'warning'}>
                {warnings.map((warning) => (
                  <p key={warning.courseId}>
                    {warning.getTooltipMessage(state.courses)}
                  </p>
                ))}
              </ReactTooltip>
            </>
          ) : (
            <></>
          )}
        </div>
        <Select
          options={options}
          defaultValue={options.filter((option) => option.value === units)}
          onChange={(value) => {
            dispatch({
              type: 'CHANGE_UNITS',
              studyPeriodId: id,
              units: value.value,
            });
          }}
          isSearchable={false}
        />
      </div>
      <div className={'grid grid-cols-studyPeriod gap-2.5 justify-start'}>
        {children}
        {
          <div className={'col-span-1'}>
            <DropBox
              state={errorState}
              message={error ? error.getDropZoneMessage(state.courses) : undefined}
            />
          </div>
        }
        {/*<div className={'w-12 col-span-1 bg-blue-200'}>*/}

        {/*</div>*/}
      </div>
    </div>
  );
};

export default StudyPeriod;
