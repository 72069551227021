import StudyPeriodClass from '../classes/StudyPeriodClass';
import StateClass from '../classes/StateClass';

const init = (initialState) => {
  return new StateClass(
    [],
    [],
    [],
    [],
      [],
      undefined,
      0,
      0
  );
};

const studyPeriodReducer = (state, action) => {
  const newActiveStudyPeriods = [];
  //copy the active study-periods
  state.activeStudyPeriods.forEach((activeStudyPeriod) =>
    newActiveStudyPeriods.push(
      new StudyPeriodClass(
        activeStudyPeriod.id,
        activeStudyPeriod.year,
        activeStudyPeriod.availabilityId,
        activeStudyPeriod.courseIds,
        activeStudyPeriod.preferredUnits,
        activeStudyPeriod.units,
        activeStudyPeriod.error,
        activeStudyPeriod.warnings,
        activeStudyPeriod.groupsOfPreReqIdsAndCourseId
      )
    )
  );

  const newState = new StateClass(
    state.courses,
    state.availabilities,
    newActiveStudyPeriods,
    structuredClone(state.completedCourseIds),
    state.defaultStudyPeriods,
    state.location,
    state.numberOfYears,
    state.defaultUnits
  );
  const currentStudyPeriod = newState.activeStudyPeriods.find(
    (studyPeriod) => studyPeriod.id === action.studyPeriodId
  );
  const currentCourse = newState.getCourse(action.courseId);

  switch (action.type) {

    case 'UPDATE_DEFAULTS':
      //console.log(action);
      //console.log(newState.defaultStudyPeriods);
      if (action.units)
        newState.defaultUnits = action.units;
      if (action.location) {
        if (action.location !== newState.defaultLocation) {
          newState.defaultLocation = action.location;
          // change the locations of studyPeriods and remove courses that aren't available in the new location
        }
        newState.setDefaultStudyPeriods(action.studyPeriods);

        // if (action.studyPeriods) {
        //   if (!action.studyPeriods.every(studyPeriod => newState.defaultStudyPeriods
        //       .some(defaultStudyPeriod => defaultStudyPeriod.availabilityId === studyPeriod.availabilityId)) && newState.defaultStudyPeriods.length === action.studyPeriods.length
        //   ) {
        //     // newState.setDefaultStudyPeriods(action.studyPeriods); // add or remove study-periods from each year
        //   }
        // }
      }
      if (action.years && action.years !== newState.numberOfYears) {
        //console.log(action.years);
        newState.setYears(action.years);
      }

      return newState;

    case 'AUTOCOMPLETE_PROGRAM':
      newState.autocomplete(action.requiredUnits);
      return newState;

    /**
     * @param courses {CourseClass[]}
     */
    case 'UPDATE_COURSES':
      action.courses.forEach((course) => {
        if (state.completedCourseIds.includes(course.courseId))
          course.setCompleted();
      });
      return new StateClass(
        action.courses,
        state.availabilities,
        state.activeStudyPeriods,
        state.completedCourseIds,
        state.defaultStudyPeriods,
        state.location,
        state.numberOfYears,
        state.defaultUnits
      );
    case 'UPDATE_AVAILABILITIES':
      return new StateClass(
        state.courses,
        action.availabilities,
        state.activeStudyPeriods,
        state.completedCourseIds,
        state.defaultStudyPeriods,
        state.location,
        state.numberOfYears,
        state.defaultUnits
      );
    /**
     * year {int}
     * studyPeriod {string}
     *
     */
    case 'ADD_STUDY_PERIOD':
      //add study-period to year with default units set as the units
        //action.study-period, use location
      newState.addStudyPeriodToYear(action.studyPeriod, action.year);
      return newState;
    /**
     * studyPeriod {string}
     * year {int}
     */
    case 'REMOVE_STUDY_PERIOD':
      //remove courses from study-period, then remove study-period from year
      //console.log(`studyPeriod: ${action.studyPeriod} year: ${action.year}`);
      const studyPeriod = newState.activeStudyPeriods.find(activeStudyPeriod => {
        //log(`activeYear: ${activeStudyPeriod.year} actionYear: ${action.year}`);
        //console.log(`active: ${activeStudyPeriod.availabilityId} action: ${newState.availabilities.find(availability => availability.studyPeriod === action.studyPeriod).availabilityId}`)
        return (
            activeStudyPeriod.year === action.year &&
            activeStudyPeriod.availabilityId === newState.availabilities.find(
                availability => availability.studyPeriod === action.studyPeriod &&
                availability.location === newState.location
            ).availabilityId
        );
      });
      newState.removeStudyPeriod(studyPeriod.id, action.year);

      return newState;
    //remove study period from year
    case 'ADD_YEAR':
      //add a new year with the year number being 1 higher than the next highest year number
      newState.addYear();
      return newState;
    case 'REMOVE_YEAR':
      newState.removeYear(action.year);
      //remove the year with year number equal to action.year
      return newState;
    case 'UPDATE_ERRORS':
      if (currentCourse) {
        newState.setErrors(action.courseId);
      } else newState.removeErrors();
      return newState;
    /**
     * add course to study-period if it is available in the study-period, similar course is not completed or in a study-period, and it is not completed or in a study-period already
     * adds warnings if pre-reqs not met or study-period goes over units
     * @param studyPeriodId {int}
     * @param courseId {int}
     */
    case 'ADD_COURSE_AND_WARNINGS':
      //errors
      if (newState.addCourseToStudyPeriod(currentCourse, currentStudyPeriod))
        return newState;
      else return state;

    /**
     * @param courseId {int}
     * @param studyPeriodId {int}
     */
    case 'TOGGLE_COMPLETED_REFACTOR':
      //currently changing the courses object in old state directly, this is bad, should fix this
      if (newState.isCourseCompleted(action.courseId)) {
        //add pre-req warnings for all study-periods that they require this course
        newState.addPreRequisiteWarningsForStudyPeriodsThatRequireThisCourse(
          action.courseId
        );
        newState.setCourseIncomplete(currentCourse);
      } else {
        //remove from study-period
        //create function for this
        if (currentStudyPeriod !== undefined)
          newState.removeCourseFromStudyPeriod(
            action.courseId,
            currentCourse.units,
            currentStudyPeriod
          );
        //update pre-req warnings for all study-periods that required this course

        newState.removePreRequisiteWarningsThatRequiredThisCourse(
          action.courseId
        );

        newState.setCourseCompleted(currentCourse);
      }
      console.log(newState);
      return newState;
    /**
     * @param courseId {int}
     * @param studyPeriodId {int}
     */
    case 'REMOVE_COURSE_AND_WARNINGS':
      newState.removeCourseFromStudyPeriod(
        action.courseId,
        currentCourse.units,
        currentStudyPeriod
      );
      console.log(newState);
      return newState;

    /**
     * @param units {int} will change preferred units to this
     * @param courseId {int} used for warning that might be pushed into the study period
     * @param studyPeriodId {int} the study-period you want to change preferred units for
     */
    case 'CHANGE_UNITS':
      currentStudyPeriod.setPreferredUnits(action.courseId, action.units);
      return newState;

    default:
      return new Error();
  }
};

export { studyPeriodReducer as default, init };
