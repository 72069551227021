import React, {useContext, useEffect, useState} from 'react';
import StudentContext from "../../context/StudentContext";
import {WarningType} from "../../classes/WarningClass";

function ProgressSidebar({
  selectedDegreeName,
  selectedOptionName,
  onToggleSidebar,
    requiredUnits
}) {

  const {state, dispatch} = useContext(StudentContext);

  const [completedUnits, setCompletedUnits] = useState([]);

  useEffect(() => {
    const array = [];
    requiredUnits.forEach(requiredUnit => {
        array.push({
          type: requiredUnit.type,
          courseType: requiredUnit.courseType,
          completedUnits: state.getUnitsCompletedForCourseType(requiredUnit.courseType)
        });
    });
    setCompletedUnits(array);
  }, [requiredUnits, state]);


  const getOverallPercentage = () => {
    const totalMinUnits = requiredUnits.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.minimumUnits;
    }, 0);
    const totalCompletedUnits = completedUnits.reduce((previousValue, currentValue) => {
        const minUnits = requiredUnits.find(requiredUnit => requiredUnit.courseType === currentValue.courseType).minimumUnits;
        return previousValue + (currentValue.completedUnits <= minUnits ? currentValue.completedUnits : minUnits);
    }, 0);

    return Math.floor((totalCompletedUnits / totalMinUnits) * 100) ;
  }

  return (
      // <aside className='drawer drawer-end' id='r-sidebar'>

      //<input type='checkbox' className='drawer-toggle' id='my-drawer-4' />

      //<div className='drawer-side h-full'>
      //  <label htmlFor='my-drawer-4' className='drawer-overlay'></label>
        <div className='menu p-4 w-full h-full bg-white flex flex-col'>
          {/* progress radial */}
          <div className='flex flex-row justify-center'>
            <div
              className='radial-progress text-uon-blue-400'
              style={{ '--value': getOverallPercentage(), '--size': '8rem' }}
            >
              <p className='text-uon-gray text-lg'>
                {getOverallPercentage()}%
                <br />
                Planned
              </p>
            </div>
          </div>

          <div className='text-center'>
            {/* Degree name */}
            <p className='text-uon-blue-500 text-2xl'>{selectedDegreeName}</p>
            {/* Option name */}
            <p className='text-uon-gray text-xl'>{selectedOptionName}</p>
          </div>


          {/* Degree Rules dd */}
          <div tabIndex='0' className='collapse collapse-arrow group'>
            <input type='checkbox' />
            <div className='collapse-title font-bold'>Degree Rules</div>

            <ul className='collapse-content flex flex-col gap-y-2'>
              {
                completedUnits.filter(completedUnit => completedUnit.type === 'degree')
                    .map(completedUnit => {
                    return (
                        <div>
                            {completedUnit.courseType} Courses
                          <progress
                              className='progress progress-primary w-56'
                              value={Math.floor(completedUnit.completedUnits/requiredUnits.find(requiredUnit => requiredUnit.courseType === completedUnit.courseType).minimumUnits * 100)}
                              max='100'
                          ></progress>
                          {completedUnit.completedUnits}/{requiredUnits.find(requiredUnit => requiredUnit.courseType === completedUnit.courseType).minimumUnits} units
                      </div>
                    )
                })
              }
            </ul>
          </div>

          {/* Option Rules dd */}
          <div tabIndex='0' className='collapse collapse-arrow group'>
            <input type='checkbox' />
            <div className='collapse-title font-bold'>Options Rules</div>

            <ul className='collapse-content flex flex-col gap-y-2'>
              {
                completedUnits.filter(completedUnit => completedUnit.type === 'option')
                    .map(completedUnit => {
                      return (
                          <div>
                            {completedUnit.courseType} Courses
                            <progress
                                className='progress progress-primary w-56'
                                value={Math.floor(completedUnit.completedUnits/requiredUnits.find(requiredUnit => requiredUnit.courseType === completedUnit.courseType).minimumUnits * 100)}
                                max='100'
                            ></progress>
                            {completedUnit.completedUnits}/{requiredUnits.find(requiredUnit => requiredUnit.courseType === completedUnit.courseType).minimumUnits} units
                          </div>
                      )
                    })
              }
            </ul>
          </div>

          {/* Progression Rules dd */}
          <div tabIndex='0' className='collapse collapse-arrow group'>
            <input type='checkbox' />
            <div className='collapse-title font-bold'>Progression Rules</div>

            <ul className='collapse-content flex flex-col gap-y-2'>
              <div className='flex flex-row gap-2'>
                {

                  state.getWarnings().filter(warning => warning.type !== WarningType.OVER_UNITS).map(warning => warning.getTooltipMessage(state.courses))
                }
              </div>
            </ul>
          </div>

          {/* Close & autocomplete */}
          <div className='flex flex-row gap-2 justify-center'>
            <button
              className='btn btn-outline btn-secondary'
              onClick={onToggleSidebar}
            >
              Close
            </button>
            <button className='btn btn-primary text-white'
                    onClick={(e) => dispatch({type: 'AUTOCOMPLETE_PROGRAM', requiredUnits})}
            >
              Autocomplete
            </button>
          </div>

          {/* Years & semester? */}
        </div>

    // </aside>
  );
}

export default ProgressSidebar;
