import React, { useReducer, useState, useEffect } from 'react';
import Header from '../components/Student/Header';
import SearchSidebar from '../components/Student/SearchSidebar';
import studyPeriodReducer, { init } from '../reducers/studyPeriod';
import SelectProgram from '../components/Student/SelectProgram';
import Year from '../components/Student/Year';
import StudyPeriod from '../components/Student/StudyPeriod';
import Course from '../components/Student/Course';
import CourseDetails from '../components/Student/CourseDetails';
import ProgressSidebar from '../components/Student/ProgressSidebar';
import CourseClass from '../classes/CourseClass';
import StudentContext from '../context/StudentContext';
// eslint-disable-next-line
import jsPDF from 'jspdf';
// eslint-disable-next-line
import html2canvas from 'html2canvas';

const prodURL = 'http://wilapi.jordancoates.com';
const devURL = 'http://localhost:4000';

const URL = prodURL;

const StudentApp = () => {
  const [courseInfo, setCourseInfo] = useState(null);
  const [openCourseInfo, setOpenCourseInfo] = useState(false);
  //reducer
  const [state, dispatch] = useReducer(studyPeriodReducer, {}, init);

  //states
  const [draggedCourse, setDraggedCourse] = useState(null);

  // availabilities from the database to be used in study periods
  const [availabilities, setAvailabilities] = useState([]);
  // state for the selected program
  const [selectedDegreeId, setSelectedDegreeId] = useState(0);
  // not being used atm because the logic behind selecting options is still being worked on
  const [selectedOptionId, setSelectedOptionId] = useState(0);

  const [selectedDegreeName, setSelectedDegreeName] = useState(null);

  const [selectedOptionName, setSelectedOptionName] = useState(null);

  const [isRemoving, setIsRemoving] = useState(false);

  const [displayProgram, setDisplayProgram] = useState(true);

  const [showProgress, setShowProgress] = useState(false);

  const [requiredUnits, setRequiredUnits] = useState([]);

  useEffect(() => {
    console.log(courseInfo);
  }, [courseInfo]);

  const courseDetailsFunction = (e) => {
    e.stopPropagation();
    const courseCode = e.target.innerText;
    setOpenCourseInfo(true);
    //setShowProgress(false);
    //document.getElementById('r-sidebar').classList.remove('col-span-2');

    setCourseInfo(
      state.courses.find((course) => course.courseCode === courseCode)
    );
  };

  // for progress sidebar
  const onToggleSidebar = () => {
    //document.getElementById('my-drawer-4').click();

    //document.getElementById('r-sidebar').classList.toggle('shadow-lg');
    setShowProgress(!showProgress);
   /*
    showProgress === true
      ? document.getElementById('r-sidebar').classList.add('col-span-2')
      : document.getElementById('r-sidebar').classList.remove('col-span-2');
   */
  };

  const addYear = () => {
    dispatch({ type: 'ADD_YEAR' });
  };

  /**
   * want to refactor this so it is easier to use but need to set up Context and make some other changes first
   * @return {JSX.Element|*[]}
   */

  const mapStudyPeriods = () => {
    if (state.courses === []) return <></>; // no courses -> return nothing
    let years = [];
    for (let i = 1; true; i++) {
      //check if a study period has a year number
      if (
        state.activeStudyPeriods.findIndex(
          (activeStudyPeriod) => activeStudyPeriod.year === i
        ) === -1
      ) {
        // once all studyPeriods are mapped, display 'Add Year' below last StudyPeriod
        years.push(
          <button
            className='flex flex-row h-48 w-full cursor-pointer justify-center items-center hover:bg-uon-blue-500 hover:bg-opacity-40 text-secondary hover:text-white'
            onClick={addYear}
          >
            <p className='text-center text-6xl select-none'>Add Year</p>
          </button>
        );
        break;
      }

      years.push(
        <Year year={i}>
          {' '}
          {state.activeStudyPeriods
            .filter((activeStudyPeriod) => activeStudyPeriod.year === i)
            .map((activeStudyPeriod) => {
              const availabilityMap = () => {
                const availability = availabilities.find(
                  (availability) =>
                    availability.availabilityId ===
                    activeStudyPeriod.availabilityId
                );
                if (availability === undefined) return 'Loading...';
                return availability.studyPeriod;
              };
              //Study Periods
              return (
                <StudyPeriod
                  name={availabilityMap()}
                  courses={state.courses}
                  error={activeStudyPeriod.error}
                  warnings={activeStudyPeriod.warnings}
                  units={activeStudyPeriod.preferredUnits}
                  dispatch={dispatch}
                  id={activeStudyPeriod.id}
                  setIsRemoving={setIsRemoving}
                >
                  {activeStudyPeriod.courseIds
                    .map((courseId) => {
                      const course = state.courses.find(
                        (course) => courseId === course.courseId
                      );
                      if (course === undefined) return null;
                      //Courses
                      return (
                        <Course
                          id={course.courseId}
                          name={course.courseName}
                          type={course.courseType}
                          code={course.courseCode}
                          courseCodeOnClick={courseDetailsFunction}
                          studyPeriodId={activeStudyPeriod.id}
                          isCompleted={state.completedCourseIds.includes(
                            course.courseId
                          )}
                        />
                      );
                    })
                    .filter((course) => course !== null)}
                </StudyPeriod>
              );
            })}{' '}
        </Year>
      );
    }
    return years;
  };

  //updates the drop-zone errors when a course is dragged from the search side-bar
  useEffect(() => {
    dispatch({ type: 'UPDATE_ERRORS', courseId: draggedCourse });
  }, [draggedCourse]);

  //FETCH SIDE EFFECTS

  //update the courses object if the user selects/changes their program
  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedDegreeId === 0) return;
      let res = await fetch(
        `${URL}/courses?degreeId=${selectedDegreeId}&optionId=${selectedOptionId}`
      );
      let requiredUnits = await fetch(
          `${URL}/requiredUnits?degreeId=${selectedDegreeId}&optionId=${selectedOptionId}`
      );
      if (res.status === 400 || requiredUnits.status === 400) throw new Error(); //SHOULD DO SOMETHING LIKE SHOW ERROR PAGE {bad request}
      if (res.status === 500 || requiredUnits.status === 500) throw new Error(); //SHOULD DO SOMETHING LIKE SHOW ERROR PAGE {server error}

      setRequiredUnits(await requiredUnits.json());

      const courses = await res.json();
      const processedCourses = await courses.map((course) => {
        return new CourseClass(
          course.courseId,
          course.courseCode,
          course.courseName,
          course.description,
          parseInt(course.units),
          course.level,
          course.isOfferedAsElective,
          course.availabilities,
          course.courseType,
          course.groupsOfRequiredCourseIds,
          course.similarCourses,
          false,
            course.preRequisiteUnits
        );
      });
      dispatch({ type: 'UPDATE_COURSES', courses: processedCourses });
    };
    fetchCourses();
  }, [selectedDegreeId, selectedOptionId]);

  //get the availabilities objects from the database
  useEffect(() => {
    const fetchAvailabilities = async () => {
      const res = await fetch(`${URL}/availabilities`);
      const availabilities = await res.json();
      setAvailabilities(availabilities);
      dispatch({type: 'UPDATE_AVAILABILITIES', availabilities});
    };
    fetchAvailabilities();
  }, []);

  //DRAG AND DROP EVENT HANDLERS
  /**
   * if a course is dropped into the delete zone from a study-period this will remove the course from the study-period it is from
   * @param e
   */
  const onDropRemoveCourseHandler = (e) => {
    e.stopPropagation();
    setIsRemoving(false);
    const courseId = parseInt(e.dataTransfer.getData('courseId'));
    const studyPeriodId = parseInt(e.dataTransfer.getData('studyPeriodId'));
    dispatch({ type: 'REMOVE_COURSE_AND_WARNINGS', courseId, studyPeriodId });
  };

  /**
   *  stops courses that were dragged from the search sidebar from being dropped in the delete zone
   * @param e
   */
  const onDragOverRemoveCourseHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.getData('studyPeriodId') !== 'undefined') {
      e.dataTransfer.dropEffect = 'move';
    } else e.dataTransfer.dropEffect = 'none';
  };

  // const printDocument = () => {
  //   const input = document.getElementById('studyPeriods');
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, 'JPEG', 0, 0);
  //     pdf.save('download.pdf');
  //   });
  // };

  return (
      <StudentContext.Provider value={{state, dispatch, availabilities}}>
        <div
          className={ isRemoving ?
          'h-screen grid grid-cols-mainLayout grid-rows-mainLayout bg-uon-gray-50 select-none items-stretch bg-red-50 gap-y-2 grid-flow-col-dense'
              :
              'h-screen grid grid-cols-mainLayout grid-rows-mainLayout bg-uon-gray-50 select-none items-stretch bg-uon-gray-50 gap-y-2 grid-flow-col-dense'
        }
          onDragStart={(e) => {
            if (e.dataTransfer.getData('studyPeriodId') === 'undefined') {
              setDraggedCourse(parseInt(e.dataTransfer.getData('courseId')));
            } else setDraggedCourse(null);
          }}
          onDragEnd={(e) => {
            setDraggedCourse(null);
          }}
          onDragEnter={(e) => {
            if (e.dataTransfer.getData('studyPeriodId') !== 'undefined') {
              setIsRemoving(true);
            }
          }}
          onDrop={onDropRemoveCourseHandler}
            //need to check that the course is in a study-period and then en
          onDragOver={onDragOverRemoveCourseHandler}
        >
          {/* header (first row) */}
          <div className={'row-span-1 col-span-3'}>
            <Header onToggleSidebar={onToggleSidebar} />
          </div>

          {/* second row */}

          <SelectProgram
              setSelectedDegreeId={setSelectedDegreeId}
              setSelectedDegreeName={setSelectedDegreeName}
              setSelectedOptionId={setSelectedOptionId}
              setSelectedOptionName={setSelectedOptionName}
              selectedDegreeName={selectedDegreeName}
              selectedDegreeId={selectedDegreeId}
              selectedOptionId={selectedOptionId}
              selectedOptionName={selectedOptionName}
              open={displayProgram}
              setIsOpen={setDisplayProgram}
          />
          {/* first column */}
          <div className={'row-span-1 col-span-1'}>
            <SearchSidebar
                courses={state.courses}
                state={state}
                dispatch={dispatch}
                availabilities={availabilities}
                setDisplayProgram={setDisplayProgram}
                courseCodeOnClick={courseDetailsFunction}
            />
          </div>
          {/* second column */}
          {/** years and study-periods go here */}
          <div
              className={'row-span-1 col-auto overflow-y-auto overscroll-contain scrollbar scrollbar-thumb-scrollbar-100 scrollbar-track-scrollbar-200 snap-y'}

          >
            {' '}
            {/* study periods */}
            {mapStudyPeriods()}
          </div>

          {/* third column */}
          {/* ProgressSidebar */}
          <div
              className={showProgress ? 'row-start-2 row-end-3 col-start-3 col-end-4 w-72 overflow-hidden visible z-0 visible' : 'row-start-2 row-end-3 col-start-3 col-end-4 w-72 overflow-hidden visible z-0 hidden'}
          >
            <ProgressSidebar
                selectedDegreeName={selectedDegreeName}
                selectedOptionName={selectedOptionName}
                requiredUnits={requiredUnits}
                onToggleSidebar={onToggleSidebar}
            />
          </div>
          {/* CourseInfoSidebar */}
          <div
              className={openCourseInfo ? 'row-start-2 row-end-3 col-start-3 col-end-4 w-72 overflow-hidden visible z-10' : 'hidden'}
          >
            <CourseDetails
                course={courseInfo}
                setCourse={setCourseInfo}
                setOpenCourseInfo={setOpenCourseInfo}
                courseCodeOnClick={courseDetailsFunction}
            />
          </div>



        </div>
      </StudentContext.Provider>
  );
};

export default StudentApp;
