import React, {useState} from 'react';
import {FaSearch, FaTimes} from "react-icons/fa";
import PropTypes from 'prop-types';

const SearchBar = ({placeholder, arrayOfData, setFilteredData}) => {

    const [searchText, setSearchText] = useState('');

    const filterData = (currentValue) => {

        const sortFunction = (one, two) => {
            const start1 = one.toLowerCase().startsWith(currentValue.toLowerCase());
            const start2 = two.toLowerCase().startsWith(currentValue.toLowerCase());
            if (start1) return -1;
            else if (start2) return 1;
            else {
                if (one.length > two.length) return 1;
                else if (one.length < two.length) return -1;
                else return 0;
            }
        }

        setSearchText(currentValue);

        let results1 = arrayOfData.filter(({value1}) => value1.toLowerCase().includes(currentValue.toLowerCase()));
        let results2 = arrayOfData.filter(({value2}) => value2 ? value2.toLowerCase().includes(currentValue.toLowerCase()) : false);
        results1 = results1.sort((data1, data2) => sortFunction(data1.value1, data2.value1));
        results2 = results2.filter(({value1}) => results1.findIndex((data) => data.value1 === value1) === -1).sort((data1, data2) => sortFunction(data1.value2, data2.value2));
        setFilteredData([...results1, ...results2]);
    }

    return (
        <div className='relative'>
            <input
                type='text'
                className='input input-bordered input-sm w-full'
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => {
                    e.stopPropagation();
                    //update the filtered data
                    const currentValue = e.target.value;
                    filterData(currentValue);
                }}
            />
            {searchText.length === 0 ? (
                <FaSearch
                    className='w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-3 opacity-60 hover:opactiy-100'/>
            ) : (
                <FaTimes
                    className='w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-3 opacity-60 hover:opactiy-100 hover:cursor-pointer'
                    onClick={(e) => {
                        e.stopPropagation();
                        filterData('');
                    }
                    }
                />
            )}
        </div>
    );
}

SearchBar.propTypes = {
    placeholder: PropTypes.string,
    arrayOfData: PropTypes.arrayOf(PropTypes.shape({
        value1: PropTypes.string.isRequired,
        value2: PropTypes.string,
        id: PropTypes.number.isRequired
    })),
    setFilteredData: PropTypes.func
}

export default SearchBar;