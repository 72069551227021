import React, { useEffect, useState, useReducer } from 'react';
import Sidebar from '../../components/Admin/Main/Sidebar';
import AdminHeader from '../../components/Admin/AdminHeader';
import AdminCourseSidebar from '../../components/Admin/Main/AdminCourseSidebar';
import CourseTypeColumn from '../../components/Admin/Main/CourseTypeColumn';
import courseTypeColumnReducer, {
  init,
} from '../../reducers/courseTypeColumnReducer';

const prodURL = 'http://wilapi.jordancoates.com';
const devURL = 'http://localhost:4000';

const URL = prodURL;

const AdminApp = () => {
  const [degrees, setDegrees] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [courses, setCourses] = useState([]);
  const [requiredUnits, setRequiredUnits] = useState([]);
  const [dragStarted, setDragStarted] = useState(false);

  const [state, dispatch] = useReducer(courseTypeColumnReducer, []);
  // {courseType: string, type: string, courses: [],

  const getDegrees = async () => {
    let res = await fetch(`${URL}/degrees`);
    if (res.status === 500) return; //should throw error
    let degrees = await res.json();
    setDegrees(
      degrees.map((degree) => ({
        value1: degree.degreeName,
        id: degree.degreeId,
      }))
    );
  };

  const getOptions = async () => {
    if (!selectedDegree) setOptions([]);
    else {
      let res = await fetch(
        `${URL}/options?degreeId=${selectedDegree.id}`
      );
      if (res.status === 500) return; //should throw error
      let options = await res.json();
      setOptions(
        options.map((option) => ({
          value1: option.optionName,
          id: option.optionId,
        }))
      );
    }
  };

  const getCourses = async () => {
    let res = await fetch(`${URL}/courses`);
    if (res.status === 400 || res.status === 500) return;
    let courses = await res.json();
    setCourses(courses);
  };

  const getRequiredUnits = async () => {
    let res1 = undefined,
      res2 = undefined;
    if (selectedDegree && selectedOption) {
      res1 = await fetch(
        `${URL}/requiredUnits?degreeId=${selectedDegree.id}&optionId=${selectedOption.id}`
      );
      res2 = await fetch(
        `${URL}/courseTypes?degreeId=${selectedDegree.id}&optionId=${selectedOption.id}`
      );
    } else if (selectedDegree) {
      res1 = await fetch(
        `${URL}/requiredUnits?degreeId=${selectedDegree.id}`
      );
      res2 = await fetch(
        `${URL}/courseTypes?degreeId=${selectedDegree.id}`
      );
    } else {
      dispatch({ type: 'UPDATE', data: [] });
      return;
    }
    if (res1 && res2 && selectedDegree) {
      if (res1.status === 400 || res2.status === 400) throw new Error();
      const requiredUnits = await res1.json();
      const idsForCourseTypes = await res2.json();
      let counter = 1;
      const data = requiredUnits
        .filter((requiredUnit) => requiredUnit.courseType !== 'ELECTIVE')
        .map((requiredUnit) => {
          const courseType = idsForCourseTypes.find(
            (courseType) => courseType.courseType === requiredUnit.courseType
          );

          return {
            id: counter++,
            type: requiredUnit.type,
            courseType: requiredUnit.courseType,
            minimumUnits: requiredUnit.minimumUnits,
            courses: courseType ? courseType.courses : [],
            error: undefined,
            selectionId:
              requiredUnit.type === 'degree'
                ? selectedDegree.id
                : selectedOption.id,
          };
        });
      dispatch({ type: 'UPDATE', data });
    }
  };

  useEffect(() => {
    getDegrees();
  }, []);

  useEffect(() => {
    setSelectedOption(undefined);
    getOptions();
  }, [selectedDegree]);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    getRequiredUnits();
  }, [selectedOption, selectedDegree, courses]);

  return (
    <div
      className={'select-none h-screen'}
      onDragOver={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const location = e.dataTransfer.getData('location');
        if (location === 'sidebar') {
          e.dataTransfer.dropEffect = 'none';
        }
      }}
      onDrop={(e) => {
        e.stopPropagation();
        //api call

        const columnId = parseInt(e.dataTransfer.getData('location'));
        const courseId = parseInt(e.dataTransfer.getData('courseId'));
        const column = state.find((column) => columnId === column.id);

        dispatch({ type: 'REMOVE', courseId, columnId });

        const removeFromOption = async () => {
          const res = await fetch(`${URL}/optionCourse`, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              courseId: courseId,
              optionId: parseInt(column.selectionId),
            }),
          });
          if (res.status !== 200) throw new Error(); //error prompt, reload page
        };
        const removeFromDegree = async () => {
          const res = await fetch(`${URL}/degreeCourse`, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              courseId: courseId,
              degreeId: parseInt(column.selectionId),
            }),
          });
          if (res.status !== 200) throw new Error(); //error prompt, reload page
        };
        if (column.type === 'degree') {
          removeFromDegree().catch((e) => console.log(e)); //network error
        } else if (column.type === 'option') {
          removeFromOption().catch((e) => console.log(e)); //network error
        }
      }}
      onDragStart={(e) => {
        e.stopPropagation();
        setDragStarted(true);
        const courseId = parseInt(e.dataTransfer.getData('courseId'));

        const alreadyInProgram = state.reduce((previous, current) => {
          console.log(previous, current);
          if (previous.hasOwnProperty('courses')) {
            return (
              previous.courses.includes(courseId) ||
              current.courses.includes(courseId)
            );
          } else if (!previous) {
            return current.courses.includes(courseId);
          } else return true;
        });
        if (alreadyInProgram) {
          dispatch({
            type: 'ERROR',
            error: 'This course has already been added to this program.',
          });
        } else {
          dispatch({ type: 'ERROR', error: undefined });
        }
      }}
      onDragEnd={(e) => {
        e.stopPropagation();
        setDragStarted(false);
        dispatch({ type: 'ERROR', error: undefined });
      }}
    >
      <AdminHeader getOptions={getOptions} />
      <div className='grid grid-cols-12'>
        <div className='grid col-span-2 h-full'>
          <Sidebar
            arrayOfData={degrees}
            heading={'Degrees'}
            subheading={'Select, edit, or remove a Degree.'}
            placeholder={'Search degrees'}
            selection={selectedDegree}
            setSelection={setSelectedDegree}
            selectionSize={'medium'}
          />
        </div>
        <div className='grid col-span-2 m-5'>
          <Sidebar
            arrayOfData={options}
            heading={'Options'}
            subheading={'Select, edit, or remove an Option.'}
            placeholder={'Search options'}
            selection={selectedOption}
            setSelection={setSelectedOption}
            selectionSize={'small'}
          />
        </div>
        <div className={'grid col-span-6 grid-cols-6 gap-2.5 '}>
          <div
            className={
              'col-span-6 bg-white h-16 flex flex-row gap-x-1 justify-center items-center shadow-lg'
            }
          >
            <p
              className={'text-uon-gray font-bold text-xl font-sans underline'}
            >
              {selectedDegree
                ? selectedDegree.value1 + (selectedOption ? ':' : '')
                : ''}
            </p>
            <p className={'text-uon-blue-600 text-lg font-sans font-bold'}>
              {selectedOption ? selectedOption.value1 : ''}
            </p>
          </div>
          <div className='col-span-2'>
            <AdminCourseSidebar
              arrayOfData={courses.map((course) => ({
                value1: course.courseCode,
                value2: course.courseName,
                id: course.courseId,
              }))}
              heading={'Courses'}
              subheading={'Select, edit, or remove a Course.'}
              placeholder={'Search Courses'}
            />
          </div>
          <div
            className={
              'col-span-4 h-fill shadow-lg bg-white grid grid-cols-4 justify-items-center'
            }
          >
            {/* need to put the columns that have the course types for the selected degree and option here */}
            {!selectedDegree ||
              state
                .filter(
                  (requiredUnit) => requiredUnit.courseType !== 'ELECTIVE'
                )
                .map((column) => (
                  <div className={'col-span-auto'}>
                    <CourseTypeColumn
                      id={column.id}
                      minimumUnits={column.minimumUnits}
                      courses={
                        column.courses
                          ? column.courses.map((courseId) =>
                              courses.find(
                                (course) => course.courseId === courseId
                              )
                            )
                          : []
                      }
                      type={column.type}
                      courseType={column.courseType}
                      dispatch={dispatch}
                      dragStarted={dragStarted}
                      error={column.error}
                      selection={column.selectionId}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminApp;
