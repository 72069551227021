import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

const DegreeDetail = () => {

  const formik = useFormik({
    initialValues: {
      degreeName: '',
      completionTime: '',
      requiredCoreUnits: '',
      requiredElectiveUnits: '',
    },
    validationSchema: Yup.object({
      degreeName: Yup.string().max(30, "Must be 30 Characters or less").required("Required"),
      completionTime: Yup.string().max(10, "Must be 10 Characters or less").required("Required"),
      requiredCoreUnits: Yup.number().max(160, "Must be 160 Characters or less").required("Required").integer('Must be a whole number'),
      requiredElectiveUnits: Yup.number().max(80, "Must be 80 Characters or less").required("Required").integer('Must be a whole number'),
    }) ,
    onSubmit: (values) => {
      console.log(values);
      console.log(formik.values)
    },
  })
  // return closeForm ? (
    return(
      <form onSubmit={formik.handleSubmit}>
      <div className='select-non text-white text-3xl gap-48 flex'>
        <div className='absolute top-0 left-0 bg-uon-blue-500 text-white w-full'>
        <label className='ml-1.5'>CREATE NEW DEGREE</label>
        </div>
        <div>
          <label 
           htmlFor='create-degree-modal'
           className='btn btn-sm absolute right-0 top-0 text-3xl bg-uon-blue-500 border-uon-blue-500 text-white'
        >X</label></div>
      </div>
      <div className='select-none'>
          <div className='mt-8 mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
            Degree Name
          </div>  
          <input
            id='degreeName'
            type='text'
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            placeholder='Degree Name'
            value={formik.values.degreeName} 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.degreeName && formik.errors.degreeName ? <p className='text-error'>{formik.errors.degreeName}</p> : null}
        </div>

        <div className='select-none'>
            <div className='mt-8 mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
              Maximum Completion Time
            </div>
            <input
              id='completionTime'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              placeholder='e.g. sem 1'
              value={formik.values.completionTime} 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.completionTime && formik.errors.completionTime ? <p className='text-error'>{formik.errors.completionTime}</p> : null}
        </div>

        <div className='select-none'>
              <div className='mt-8 mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
                Required units for course types
              </div>
              <div>
              <div className='mt-2'>
                CORE:
              </div>
                <input 
                 id='requiredCoreUnits'
                 type='text'
                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-32 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-center'
                 value={formik.values.requiredCoreUnits} 
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 />
                 {formik.touched.requiredCoreUnits && formik.errors.requiredCoreUnits ? <p className='text-error'>{formik.errors.requiredCoreUnits}</p> : null}
              </div>
              <div>
              <div className='mt-2'>
                ELECTIVE:
              </div>
                <input 
                 id='requiredElectiveUnits'
                 type='text'
                 className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-center'
                 value={formik.values.requiredElectiveUnits} 
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 />
                 {formik.touched.requiredElectiveUnits && formik.errors.requiredElectiveUnits ? <p className='text-error'>{formik.errors.requiredElectiveUnits}</p> : null}
              </div>
            </div><br/><br/><br/><br/>

        <div className='flex flex-row gap-2'>
        <label
            htmlFor='create-degree-modal'
            className='btn btn-outline w-32 text-2xl capitalize'
          >
            Cancel
          </label>
          <button
            type='submit'
            className='btn btn-primary w-32 text-white text-3xl capitalize'
          >
            Add
          </button>
        </div>
  </form>
    )
  // ) : "";
};

export default DegreeDetail;

// const [errorMessage, setErrorMessage] = useState(null);
  
// const [name, setName] = useState('')
// const [completionTime, setCompletionTime] = useState('')
// const [coreUnits, setCoreUnits] = useState('')
// const [electiveUnits, setElectiveUnits] = useState('')

// const error = () => {
  //   if (!name || !completionTime || !electiveUnits || !coreUnits) {
  //     setErrorMessage('Please fill the informations.');
  //   } 
  //   else {
  //     setErrorMessage(null);
  //   }
  // };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   if (!name || !completionTime || !electiveUnits || !coreUnits) {
  //     // error();
  //     return;
  //   } else {
  //     fetch('http://localhost:5000/degrees/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         name: name,
  //         requiredCoreUnits: coreUnits,
  //         requiredElectiveUnits: electiveUnits,
  //         completionTime: completionTime,
  //       }),
  //     }
  //     );
  //     reset();
  //   }
  //   console.log(name);
  //   console.log(coreUnits);
  //   console.log(completionTime);
  //   console.log(electiveUnits);
  //   console.log(<Degree name={name} coreUnits={coreUnits} completionTime={completionTime} electiveUnits={electiveUnits}/>)
  // };

    // const reset = () => {
  //   setName('')
  //   setElectiveUnits('')
  //   setCoreUnits('')
  //   setCompletionTime('')
  //   setErrorMessage(null)
  // }
          {/* <div>
          {errorMessage ? (
            <p className='text-red-500 text-3xl font-sans font-bold '>
              {errorMessage}
            </p>
          ) : (
            <></>
          )}
        </div><br/> */}
