import { useState, useEffect } from 'react';
import Course from './Course';
import SearchBar from '../SearchBar';

const SearchSidebar = ({
  courseCodeOnClick,
  setDisplayProgram,
  dispatch,
  state,
  availabilities,
}) => {
  // filters
  const [unitFilters, setUnitFilters] = useState([]);
  const [courseTypeFilters, setCourseTypeFilters] = useState([]);

  // course arrays
  const [search, setSearch] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [displayedCourses, setDisplayedCourses] = useState([]);

  // active filters
  const [filters, setFilters] = useState([]);

  // when a degree/option is selected run
  useEffect(() => {
    setUnitFilters(mapUnits);
    setCourseTypeFilters(mapCourseTypes);
    setDisplayedCourses(state.courses);
  }, [state.courses]);

  function intersect(a, b) {
    return a.filter(Set.prototype.has, new Set(b));
  }

  // whenever filtered courses / search is changed run
  useEffect(() => {
    if (filters.length !== 0 && search.length !== 0) {
      setDisplayedCourses(intersect(filteredCourses, search));
    } else if (filters.length !== 0) {
      setDisplayedCourses(filteredCourses);
    } else if (search.length !== 0) {
      setDisplayedCourses(search);
    } else {
      setDisplayedCourses(state.courses);
    }
  }, [filters, search, filteredCourses, state.courses]);

  const mapUnits = () => {
    return Array.from(
      new Set(
        state.courses.map((course) => {
          return course.units;
        })
      )
    );
  };

  const mapCourseTypes = () => {
    return Array.from(
      new Set(
        state.courses.map((course) => {
          return course.courseType;
        })
      )
    );
  };

  const mapAvailabilities = () => {
    const availabilityIds = [];
    state.courses.forEach((course) => {
      course.availabilities.forEach((availability) => {
        if (
          availabilityIds.findIndex(
            (availabilityId) => availabilityId === availability
          ) === -1
        ) {
          availabilityIds.push(availability);
        }
      });
    });
    //creates an array of availability Id's for all the Id's that exist in the courses array
    const availabilitiesInCourses = availabilities.filter(
      (availability) =>
        availabilityIds.findIndex(
          (availabilityId) => availabilityId === availability.availabilityId
        ) !== -1
    );

    let locations = [];
    let studyPeriods = [];

    //push locations into the set
    availabilitiesInCourses.forEach((availabilityInCourse) =>
      locations.push(availabilityInCourse.location)
    );

    //push availabilities into set
    availabilitiesInCourses.forEach((availabilityInCourse) =>
      studyPeriods.push(availabilityInCourse.studyPeriod)
    );

    locations = Array.from(new Set(locations));
    studyPeriods = Array.from(new Set(studyPeriods));

    return {
      locations,
      studyPeriods,
      availabilitiesInCourses,
    };
  };

  // handle filters
  const handleOnChange = (value, filterOn) => (e) => {
    //console.log(value);
    //console.log(filterOn);

    // (for availability)-- converting value from param to substring of availabilityId
    switch (value) {
      case 'Semester1':
        value = 'S1';
        break;
      case 'Semester2':
        value = 'S2';
        break;
      case 'Trimester1':
        value = 'T1';
        break;
      case 'Trimester2':
        value = 'T2';
        break;
      case 'Trimester3':
        value = 'T3';
        break;
      case 'Callaghan':
        value = 'C';
        break;
      case 'Ourimbah':
        value = 'O';
        break;
      case 'Online':
        value = 'W';
        break;
      case 'Singapore':
        value = 'S';
        break;
      case 'UNCIE':
        value = 'U';
        break;
      case 'Summer':
        value = 'Sum';
        break;
      case 'BCASingapore':
        value = 'B';
        break;
      default:
        break;
    }

    let results = [];
    // if checkbox is checked, push the filterOn & value into the filters array
    if (e.target.checked) filters.push({ filterOn, value });
    else {
      // if checkbox is unchecked, remove that filter from filters array
      filters.splice(
        filters.findIndex((filter) => {
          return filter.value === value;
        }),
        1
      );

      // remove the courses associated to unchecked filter from filteredCourses list
      filteredCourses.splice(
        filteredCourses.forEach((course) => {
          if (filterOn === 'availabilities') {
            course[filterOn].forEach((code) => {
              return code[0] === value || code.substr(1, 2) === value;
            });
          } else return course[filterOn] === value;
        }),
        1
      );
    }

    let applied = [];
    // adding courses to filteredCourses
    filters.forEach((filter, i) => {

      // FIRST item handle
      if (i === 0) {
        console.log('went into i === 0');
        if (
          filter.filterOn === 'studyPeriod' ||
          filter.filterOn === 'location'
        ) {
          console.log('went into availabilities');
          state.courses.forEach((course) =>
            course.availabilities.forEach((period) => {
              if (
                (value.length === 1 && period[0] === filter.value) || // for locations U,B,C,O,W,S
                period.substr(1, 2) === filter.value || // for S1, S2, T1, T2, T3
                period.substr(2, 3) === filter.value || // for BSS1
                period.substr(1, 3) === filter.value // for Sum
              ) {
                results.push(course); // filteredCourses is set to results later
              }
            })
          );
        } else {
          console.log('went into NOT availabilities');

          results = state.courses.filter((course) => {
            return course[filter.filterOn] === filter.value;
          });
        }

        applied.push(filter.filterOn);
        return;
      }

      // 'OR' relationship case
      if (applied.includes(filter.filterOn)) {
        console.log('went into OR');
        if (
          filter.filterOn === 'studyPeriod' ||
          filter.filterOn === 'location'
        ) {
          console.log('went into availabilities');
          state.courses.forEach((course) =>
            course.availabilities.forEach((period) => {
              if (
                (value.length === 1 && period[0] === filter.value) || // for locations U,B,C,O,W,S
                period.substr(1, 2) === filter.value || // for S1, S2, T1, T2, T3
                period.substr(2, 3) === filter.value || // for BSS1
                period.substr(1, 3) === filter.value // for Sum
              ) {
                results.push(course);
              }
            })
          );
        } else {
          console.log('went into NOT availabilities');
          state.courses
            .filter((course) => course[filter.filterOn] === filter.value)
            .forEach((filteredCourse) => {
              results.push(filteredCourse);
            });
        }
      }

      // 'AND' relationship case
      // if the filter.filterOn != previous filterOn, filter filtered list
      else {
        console.log('went into AND');
        if (
          filter.filterOn === 'studyPeriod' ||
          filter.filterOn === 'location'
        ) {
          console.log('went into availabilities');
          let other = [];
          results.forEach((course) => {
            course.availabilities.forEach((period) => {
              if (
                (value.length === 1 && period[0] === filter.value) || // for locations U,B,C,O,W,S
                period.substr(1, 2) === filter.value || // for S1, S2, T1, T2, T3
                period.substr(2, 3) === filter.value || // for BSS1
                period.substr(1, 3) === filter.value // for Sum
              ) {
                other.push(course);
              }
            });
          });
          results = other;
        } else {
          results = results.filter((course) => {
            return course[filter.filterOn] === filter.value;
          });
        }
        applied.push(filter.filterOn);
      }
    });

    results = Array.from(new Set(results));
    setFilteredCourses(results);
  };

  return (
    <div
      className='h-full bg-white shadow-lg px-2 flex flex-col gap-y-3 items-stretch'
      style={{ width: '320px' }}
    >
      <button
        className='btn btn-link text-uon-blue-500 hover:text-uon-blue-600'
        onClick={() => {
          setDisplayProgram(true);
        }}
      >
        Select program
      </button>
      <div>
        {/*<SearchInput*/}
        {/*  placeholder={'Search Courses'}*/}
        {/*  // courses array sent to search*/}
        {/*  inputArray={state.courses}*/}
        {/*  setSearchResults={setSearch}*/}
        {/*/>*/}
        <SearchBar
            placeholder={'Search Courses'}
            // courses array sent to search
            arrayOfData={state.courses.map(course => ({value1: course.courseCode, value2: course.courseName, id: course.courseId}))}
            setFilteredData={(courseObjects) => setSearch(state.courses.filter(course => courseObjects.findIndex(courseObject => courseObject.id === course.courseId) !== -1))}
        />
      </div>
      <div className={'flex-shrink-0 flex-grow-0'}>
      {/* Completed Courses */}
      <div className='form-control flex flex-row gap-2'>
        <input
          type='checkbox'
          className='checkbox checkbox-primary'
          onChange={handleOnChange(true, 'completed')}
        />
        <span className='label-text'>Completed Courses</span>
      </div>

      <hr />

      {/* Course Type */}
      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <div className='collapse-title font-bold'>Course Type</div>

        <ul className='collapse-content' style={{ paddingBottom: 0 }}>
          <div className='flex flex-row flex-wrap'>
            {courseTypeFilters.map((courseType) => {
              return (
                <div className='form-control'>
                  <label className='label cursor-pointer pt-0'>
                    <input
                      type='checkbox'
                      className='checkbox checkbox-primary'
                      onChange={handleOnChange(courseType, 'courseType')}
                    />
                    <span className='pl-1 label-text lowercase first-line:capitalize'>
                      {courseType}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        </ul>
      </div>

      {/* Units */}
      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <div className='collapse-title font-bold'>Units</div>
        <ul className='collapse-content' style={{ paddingBottom: 0 }}>
          <div className='flex flex-row flex-wrap gap-2'>
            {unitFilters.map((unit) => {
              return (
                <div className='form-control'>
                  <label className='label cursor-pointer pt-0'>
                    <input
                      type='checkbox'
                      className='checkbox checkbox-primary'
                      onChange={handleOnChange(unit, 'units')}
                    />
                    <span className='pl-1 label-text'>{unit}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </ul>
      </div>

      {/* StudyPeriod */}
      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <div className='collapse-title font-bold'>Availability</div>
        <ul className='collapse-content' style={{ paddingBottom: 0 }}>
          <div className='flex flex-row flex-wrap'>
            {mapAvailabilities(state.courses) &&
              mapAvailabilities(state.courses).studyPeriods.map((period) => {
                return (
                  <div className='form-control'>
                    <label className='label cursor-pointer pt-0'>
                      <input
                        type='checkbox'
                        className='checkbox checkbox-primary'
                        onChange={handleOnChange(period, 'studyPeriod')}
                      />
                      <span className='pl-1 label-text'>{period}</span>
                    </label>
                  </div>
                );
              })}
          </div>
        </ul>
      </div>

      {/* Location */}
      <div tabIndex='0' className='collapse collapse-arrow group'>
        <input type='checkbox' />
        <div className='collapse-title font-bold'>Location</div>
        <ul className='collapse-content' style={{ paddingBottom: 0 }}>
          <div className='flex flex-row flex-wrap'>
            {mapAvailabilities(state.courses) &&
              mapAvailabilities(state.courses).locations.map((location) => {
                return (
                  <div className='form-control'>
                    <label className='label cursor-pointer pt-0'>
                      <input
                        type='checkbox'
                        className='checkbox checkbox-primary'
                        onChange={handleOnChange(location, 'location')}
                      />
                      <span className='pl-1 label-text'>{location}</span>
                    </label>
                  </div>
                );
              })}
          </div>
        </ul>
      </div>
      </div>

      <div className={'flex flex-row flex-wrap  content-start gap-1.5 justify-center overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-100 scrollbar-track-scrollbar-200 snap-y'}>
        {displayedCourses.map((course) => {
          return (
            <Course
              id={course.courseId}
              name={course.courseName}
              code={course.courseCode}
              type={course.courseType}
              courseCodeOnClick={courseCodeOnClick}
              isCompleted={state.completedCourseIds.includes(course.courseId)}
              dispatch={dispatch}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SearchSidebar;
