import React from 'react';
import { IconContext } from 'react-icons';
import { HiOutlineCog, HiOutlineMenu } from 'react-icons/hi';
import { ReactComponent as UonLogo } from '../../assets/svg/uonLogo.svg';

function Header({ onToggleSidebar }) {
  return (
    <div
      className={
        'w-full h-full shadow-lg bg-white z-20 flex flex-row justify-between items-center'
      }
    >
      <UonLogo />

      <div className={'flex items-center'}>
        <h1 className={'text-uon-blue-500 font-sans text-4xl font-bold'}>my</h1>
        <h1 className={'text-secondary font-sans uppercase font-bold text-4xl'}>
          program
        </h1>
      </div>

      <div className={'flex items-center gap-x-2.5'}>
        <IconContext.Provider value={{ size: '45px' }}>
          {/*<HiOutlineCog*/}
          {/*  className={*/}
          {/*    'text-stone-500 hover:bg-blue-50 cursor-pointer p-0.5 rounded-md'*/}
          {/*  }*/}
          {/*/>*/}
          <div>
            <HiOutlineMenu
              className={
                'text-secondary hover:bg-blue-50 cursor-pointer p-0.5 rounded-md'
              }
              onClick={() => {
                onToggleSidebar();
              }}
            />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
}

export default Header;
