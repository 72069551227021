import React, { useState, useEffect, useContext } from 'react';
import Draggable from 'react-draggable';
import Select from 'react-select';
import Button from '../Button';
import StudentContext from '../../context/StudentContext';

const prodURL = 'http://wilapi.jordancoates.com';
const devURL = 'http://localhost:4000';

const URL = prodURL;

//you are only able to select a degree currently as the logic behind selecting options needs work
const SelectProgram = ({
  setSelectedDegreeId,
  setSelectedOptionId,
  setSelectedDegreeName,
  setSelectedOptionName,
  selectedDegreeName,
  selectedOptionName,
  selectedDegreeId,
  selectedOptionId,
  open,
  setIsOpen,
}) => {
  const [programs, setPrograms] = useState([]);
  const [currentDegree, setCurrentDegree] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);
  const [currentYear, setCurrentYear] = useState({
    label: '3 years',
    value: 3,
  });
  const [currentStudyPeriods, setCurrentStudyPeriods] = useState([
    {
      label: 'Semester1',
      value: {
        availabilityId: 'CS1',
        location: 'Callaghan',
        studyPeriod: 'Semester1',
      },
    },
    {
      label: 'Semester2',
      value: {
        availabilityId: 'CS2',
        location: 'Callaghan',
        studyPeriod: 'Semester2',
      },
    },
  ]);
  const [currentLocation, setCurrentLocation] = useState({
    label: 'Callaghan',
    value: 'Callaghan',
  });
  const [currentUnits, setCurrentUnits] = useState({
    label: '40 units',
    value: 40,
  });
  const [visible, setVisible] = useState(' hidden');
  const [errorMessage, setErrorMessage] = useState(false);
  const { state, dispatch } = useContext(StudentContext);

  const submitHandler = () => {
    if (!currentDegree) {
      setErrorMessage(true);
    } else if (currentStudyPeriods.length === 0) {
      setErrorMessage(true);
    } else {
      setSelectedDegreeId(currentDegree.value);
      setSelectedDegreeName(currentDegree.label);
      if (currentOption) {
        setSelectedOptionId(currentOption.value);
        setSelectedOptionName(currentOption.label);
      } else {
        setSelectedOptionName(null);
        setSelectedOptionId(0);
      }
      dispatch({
        type: 'UPDATE_DEFAULTS',
        units: currentUnits.value,
        location: currentLocation.value,
        studyPeriods: currentStudyPeriods.map(
          (currentStudyPeriod) => currentStudyPeriod.value
        ),
        years: currentYear.value,
      });
      //set the option ids
      setIsOpen(false);
    }
  };

  // fetch programs
  useEffect(() => {
    fetch(`${URL}/programs`)
      .then((response) => response.json())
      .then((data) => setPrograms(data));
  }, []);

  // mapping options associated with selected program
  const mapOptions = () => {
    if (!currentDegree) return [];
    const currentProgram = programs.find(
      (program) => program.degreeId === currentDegree.value
    );
    if (currentProgram === undefined) return [];
    return currentProgram.options.map((option) => ({
      value: option.optionId,
      label: option.optionName,
    }));
  };

  // Units dropdown (10-40 at the moment, change as desired)
  const mapDefaultUnits = () => {
    const defaultUnits = [];
    for (let i = 10; i <= 40; i += 10)
      defaultUnits.push({ value: i, label: i + ' units' });
    return defaultUnits;
  };

  // Years dropdown (1-8 as max part time is usually 8)
  const mapNumberOfYears = () => {
    const numberOfYears = [];
    if (programs.length !== 0 && currentDegree) {
      const program = programs.find(
        (program) => program.degreeId === currentDegree.value
      );
      //console.log(program);
      for (
        let i = 1;
        i <= (program ? parseInt(program.maximumDuration) : 0);
        i++
      )
        numberOfYears.push({
          value: i,
          label: i + ' years',
        });
    }
    return numberOfYears;
  };

  // getting unique locations & study periods
  const mapAvailabilities = () => {
    let locations = [];
    let studyPeriods = [];
    state.availabilities.forEach((availability) => {
      locations.push(availability.location);
      studyPeriods.push(availability.studyPeriod);
    });

    locations = Array.from(new Set(locations));
    studyPeriods = Array.from(new Set(studyPeriods));

    return { locations, studyPeriods };
  };

  // Update the defaultStudyPeriod state based on checked checkboxes
  const handleStudyPeriods = (period) => (e) => {
    //console.log('period = ' + period);
    //console.log(state.defaultStudyPeriods);
    e.target.checked
      ? state.defaultStudyPeriods.push(period)
      : // if checkbox is unchecked, remove that studyPeriod from defaultStudyPeriods array
        state.defaultStudyPeriods.splice(
          state.defaultStudyPeriods.findIndex((period) => {
            return period.value === period;
          }),
          1
        );
  };

  useEffect(() => {
    if (open) {
      if (selectedDegreeName) {
        setCurrentDegree({
          value: selectedDegreeId,
          label: selectedDegreeName,
        });
        if (selectedOptionName)
          setCurrentOption({
            value: selectedOptionId,
            label: selectedOptionName,
          });
      }
      setCurrentLocation((location) =>
        state.location
          ? { label: state.location, value: state.location }
          : location
      );
      setCurrentStudyPeriods((studyPeriods) => {
        if (state.location)
          return state.defaultStudyPeriods.length > 0
            ? state.defaultStudyPeriods.map((defaultStudyPeriod) => ({
                label: defaultStudyPeriod.studyPeriod,
                value: defaultStudyPeriod,
              }))
            : studyPeriods;
        return studyPeriods;
      });
      setCurrentUnits((currentUnits) =>
        state.defaultUnits !== 0
          ? { label: state.defaultUnits + ' units', value: state.defaultUnits }
          : currentUnits
      );
    } else setVisible(' hidden');
  }, [open]);

  let selectProgramModal = (
    <Draggable onDrag={(e) => e.stopPropagation()}>
      <div
        className={
          'fixed left-1/2 cursor-move flex flex-col w-96 h-fit p-4 gap-y-2.5 items-center justify-start shadow-lg border rounded-sm z-50 bg-white border-stone-300 focus:border-blue-500 visible'
        }
        tabIndex={0}
      >
        <div className='flex justify-end w-full flex-row'>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            size={'30px'}
          />
        </div>
        <h1
          className='text-secondary font-sans text-4xl cursor-default mb-4'
          onDrag={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          Select Program
        </h1>

        {/* Select Dropdowns */}

        {/* Select degree */}
        <div
          className={'items-left w-full'}
          onClick={(e) => e.stopPropagation()}
          onDrag={(e) => e.stopPropagation()}
        >
          <div
            className={
              'flex flex-row text-uon-blue-900 text-md font-bold text-left'
            }
          >
            <label htmlFor={'select-degree'}>Select Degree:</label>
            {!currentDegree ? <p className={'text-red-500'}>*</p> : <></>}
          </div>
          <Select
            name={'select-degree'}
            placeholder={'Select your degree...'}
            isClearable={false}
            value={currentDegree}
            onChange={(value, { action }) => {
              setCurrentOption(null);
              if (action === 'select-option') setCurrentDegree(value);
            }}
            options={programs.map((program) => ({
              value: program.degreeId,
              label: program.degreeName,
            }))}
            className={
              !currentDegree && errorMessage
                ? 'w-full cursor-default border border-solid border-red-500 rounded-md'
                : 'w-full cursor-default'
            }
            isSearchable={true}
          />
        </div>
        {/* Select option */}
        <div className={'items-left w-full'}>
          <label
            htmlFor={'select-option'}
            className={'text-uon-blue-900 text-md font-normal text-left'}
          >
            Select Option:
          </label>
          <Select
            placeholder={'Select your option...'}
            name={'select option'}
            className={'w-full cursor-default'}
            value={currentOption}
            noOptionsMessage={() =>
              currentDegree
                ? 'This degree has no options.'
                : 'You must select a degree first.'
            }
            isClearable={true}
            onChange={(value, { action }) => {
              if (action === 'select-option') setCurrentOption(value);
              if (action === 'clear') setCurrentOption(null);
            }}
            options={mapOptions()}
            isSearchable={false}
          />
        </div>
        {/* Selection years */}
        <div className={'items-left w-full'}>
          <label
            htmlFor={'select-year'}
            className={'text-uon-blue-900 text-md font-normal text-left'}
          >
            Select Number of Years:
          </label>
          <Select
            placeholder={'Number of Years'}
            name={'select-year'}
            className='w-full cursor-default'
            noOptionsMessage={() =>
              currentDegree ? 'Error' : 'You must select a degree first.'
            }
            value={currentYear}
            options={mapNumberOfYears()}
            onChange={(value, { action }) => {
              if (action === 'select-option') setCurrentYear(value);
            }}
          />
        </div>
        {/* Select units */}
        <div className={'items-left w-full'}>
          <label className={'text-uon-blue-900 text-md font-normal text-left'}>
            Select Default Units:
          </label>
          <Select
            placeholder={'Default Units for Study Periods'}
            className='w-full cursor-default'
            value={currentUnits}
            options={mapDefaultUnits()}
            onChange={(value, { action }) => {
              if (action === 'select-option') setCurrentUnits(value);
            }}
          />
        </div>

        {/* Select location */}
        <div className={'items-left w-full'}>
          <label className={'text-uon-blue-900 text-md font-bold text-left'}>
            Select Location:
          </label>
          <Select
            placeholder={'Choose Location'}
            className='w-full cursor-default'
            defaultValue={
              state.location
                ? { label: state.location, value: state.location }
                : null
            }
            options={mapAvailabilities().locations.map((location) => ({
              value: location,
              label: location,
            }))}
            value={currentLocation}
            onChange={(value, action) => {
              if (action.action === 'select-option') {
                setCurrentStudyPeriods((currentStudyPeriods) => {
                  return currentStudyPeriods
                    .filter((currentStudyPeriod) =>
                      state.availabilities.some(
                        (availability) =>
                          availability.studyPeriod ===
                            currentStudyPeriod.value.studyPeriod &&
                          availability.location === value.value
                      )
                    )
                    .map((currentStudyPeriod) => ({
                      label: currentStudyPeriod.label,
                      value: state.availabilities.find(
                        (availability) =>
                          availability.studyPeriod ===
                            currentStudyPeriod.value.studyPeriod &&
                          availability.location === value.value
                      ),
                    }));
                });
                setCurrentLocation(value);
              }
            }}
          />
        </div>

        {/* Select default study periods */}
        <div className={'items-left w-full'}>
          <div className={'flex flex-row'}>
            <label
              className={'text-uon-blue-900 text-md font-normal text-left'}
            >
              Select Default Study Periods:
            </label>
            {currentStudyPeriods.length === 0 ? (
              <p className={'text-red-600'}>*</p>
            ) : (
              <></>
            )}
          </div>
          <Select
            placeholder={'Choose Default Study Periods'}
            value={currentStudyPeriods.length > 0 ? currentStudyPeriods : null}
            noOptionsMessage={() =>
              currentLocation
                ? 'There are no more study-periods to display for the selected location.'
                : 'You must select a location first.'
            }
            isMulti={true}
            className={
              currentStudyPeriods.length === 0 && errorMessage
                ? 'w-full cursor-default border border-solid border-red-500 rounded-md'
                : 'w-full cursor-default'
            }
            filterOption={(option) => {
              return (
                option.value.location === currentLocation.value &&
                !currentStudyPeriods.some(
                  (currentStudyPeriod) =>
                    option.value.availabilityId ===
                    currentStudyPeriod.value.availabilityId
                )
              );
            }}
            defaultValue={
              state.defaultStudyPeriods.length > 0
                ? state.defaultStudyPeriods
                : null
            }
            options={state.availabilities.map((availability) => ({
              label: availability.studyPeriod,
              value: availability,
            }))}
            onChange={(values, action) => {
              if (action.action === 'select-option')
                setCurrentStudyPeriods((value) => {
                  return [...value, action.option];
                });
              else if (action.action === 'remove-value') {
                setCurrentStudyPeriods(() =>
                  currentStudyPeriods.filter(
                    (currentStudyPeriod) =>
                      currentStudyPeriod.value.availabilityId !==
                      action.removedValue.value.availabilityId
                  )
                );
              } else if (action.action === 'clear') setCurrentStudyPeriods([]);
            }}
          />
        </div>

        {/*/!* Default Study Periods *!/*/}
        {/*<p className='text-xl w-full text-secondary font-semibold leading-5 mt-3 ml-6 mb-4'>*/}
        {/*  Default Study Periods <br/>*/}
        {/*  <span className='text-xs text-secondary font-normal'>*/}
        {/*    These will be added to each year by default*/}
        {/*  </span>*/}
        {/*</p>*/}

        {/*/!* Default Study Periods Checkboxes *!/*/}
        {/*{mapAvailabilities().studyPeriods.map((period) => {*/}
        {/*  return (*/}
        {/*      <div className='form-control w-full'>*/}
        {/*        <label className='label cursor-pointer p-0 ml-6'>*/}
        {/*          <span className='label-text text-base'>{period}</span>*/}
        {/*          <input*/}
        {/*              type='checkbox'*/}
        {/*              className='checkbox checkbox-primary'*/}
        {/*              onChange={handleStudyPeriods(period)}*/}
        {/*          />*/}
        {/*        </label>*/}
        {/*      </div>*/}
        {/*  );*/}
        {/*})}*/}

        {/* Errors */}
        <div className='bg-white grow'>
          {errorMessage ? (
            <p className='font-sans text-red-600 text-md'>{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>

        {/* Submit & Close Buttons */}
        <div className='flex flex-row gap-x-1 justify-end w-full'>
          <Button
            textButton={true}
            text={'Close'}
            onClick={() => {
              setIsOpen(false);
              // console.log(state.defaultUnits);
              // console.log(state.defaultStudyPeriods);
              // console.log(state.location);
              // console.log(state.availabilities);
              // console.log(mapAvailabilities().locations);
              // console.log(state.defaultStudyPeriods)
            }}
            filled={false}
          />
          <Button
            textButton={true}
            text={'Submit'}
            onClick={submitHandler}
            filled={true}
          />
        </div>
      </div>
    </Draggable>
  );
  if (!open) selectProgramModal = <></>;

  return <>{selectProgramModal}</>;
};

export default SelectProgram;
