import React from 'react';

class DropBoxState {
  static WARNING = new DropBoxState('warning');
  static ERROR = new DropBoxState('error');
  static DROP = new DropBoxState('drop');
  static DEFAULT = new DropBoxState('default');

  constructor(state) {
    this.state = state;
  }
}

const DropBox = ({ state, message }) => {
  let style =
    'flex flex-col w-28 h-28 border-dashed rounded-md border items-center justify-center ';
  let text = 'font-sans font-bold text-sm';

  switch (state) {
    case DropBoxState.DROP:
      style += ' border-2 border-uon-blue-600 bg-uon-blue-50';
      text += ' text-uon-blue-600';
      break;
    case DropBoxState.WARNING:
      style += ' border-2 border-yellow-500 bg-yellow-200';
      text += ' text-stone-500 text-center';
      break;
    case DropBoxState.ERROR:
      style += ' border-2 border-uon-blue-100 bg-uon-blue-50';
      text += ' text-stone-500 text-center';
      break;
    case DropBoxState.DEFAULT:
      style += ' border border-stone-700 bg-white';
      text += ' text-stone-400';
  }
  return (
    <div className={style}>
      <p className={text}>{message || 'Drop here'}</p>
    </div>
  );
};

export { DropBox as default, DropBoxState };
