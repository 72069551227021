import React, { useState } from 'react';
import { HiXCircle, HiOutlineXCircle } from 'react-icons/hi';
import { IconContext } from 'react-icons';

const Button = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const buttonPicker = () => {
    if (isHovering && props.textButton && props.filled)
      return (
        <div
          className={
            'bg-blue-600 border border-blue-600 border-solid px-3 py-1 cursor-pointer rounded-2xl'
          }
          onMouseLeave={() => setIsHovering(false)}
          onClick={props.onClick}
        >
          <p className={'font-sans text-white text-md'}>{props.text}</p>
        </div>
      );
    else if (!isHovering && props.textButton && props.filled)
      return (
        <div
          className={
            'bg-stone-600 border border-stone-600 border-solid px-3 py-1 cursor-pointer rounded-2xl'
          }
          onMouseOver={() => setIsHovering(true)}
        >
          <p className={'font-sans text-white text-md'}>{props.text}</p>
        </div>
      );
    else if (isHovering && props.textButton && !props.filled)
      return (
        <div
          className={
            'bg-white border border-red-600 border-solid px-3 py-1 cursor-pointer rounded-2xl'
          }
          onMouseLeave={() => setIsHovering(false)}
          onClick={props.onClick}
        >
          <p className={'font-sans text-red-600 text-md'}>{props.text}</p>
        </div>
      );
    else if (!isHovering && props.textButton && !props.filled)
      return (
        <div
          className={
            'bg-white border border-stone-600 border-solid px-3 py-1 cursor-pointer rounded-2xl'
          }
          onMouseOver={() => setIsHovering(true)}
        >
          <p className={'font-sans text-stone-600 text-md'}>{props.text}</p>
        </div>
      );
    else if (isHovering)
      return (
        <IconContext.Provider value={{ size: props.size }}>
          <HiXCircle
            className={'text-red-600 cursor-pointer'}
            onMouseLeave={() => setIsHovering(false)}
            onClick={props.onClick}
          />
        </IconContext.Provider>
      );
    else
      return (
        <IconContext.Provider value={{ size: props.size }}>
          <HiOutlineXCircle
            className={'text-stone-600 cursor-pointer'}
            onMouseOver={() => setIsHovering(true)}
          />
        </IconContext.Provider>
      );
  };

  return <div>{buttonPicker()}</div>;
};

export default Button;
