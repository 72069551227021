import { useFormik, Form, Field } from 'formik';
import { useState } from 'react';
import Select from 'react-select';
import SelectLevel from './DropDownBox';
import SelectUnit from './DropDownBox';
import SelectPrerequisite from './DropDownBox';
import SelectSimilarCourses from './DropDownBox';
import * as Yup from 'yup';
import Course from './Course'
import CreatePrerequisite from './CreatePrerequisite';

const CourseDetail = () => {
  const [group, setGroup] = useState([{}]);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [location, setLocation] = useState([[]]);
  const [duration, setDuration] = useState([]);
  
  const onCheckedDuration = (checkedDuration, value) => {
    if(checkedDuration){
      setDuration([...duration, value]);
      formik.setFieldValue('studyPeriod', [...duration, value])
    } else if(!checkedDuration){
      setDuration(duration.filter(item => item !== value));
    } else if(value === 'All')
    {
      setDuration([...duration, value]);
      formik.setFieldValue('studyPeriod', [...duration, value])
    }
  }

  const checkedLocation = (id,value) => {
    const locationList = [...location];
    if(id ==='Callahgan'){
      setChecked(!checked);  
    } else if(id === 'Singapore'){
      setChecked2(!checked2);  
    } else if(id === 'Newcastle'){
      setChecked3(!checked3);  
    } else if(id === 'Online'){
      setChecked4(!checked4);  
      //setLocation([value]);
    }
    setLocation(locationList)
    formik.setFieldValue('location', [value])
  }
  const levelOption = [
    { value: '1000', label: '1000' },
    { value: '2000', label: '2000' },
    { value: '3000', label: '3000' },
    { value: '4000', label: '4000' },
    { value: '5000', label: '5000' },
    { value: '6000', label: '6000' }]
    
  const UnitsOption = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
    { value: '40', label: '40' },
    { value: '50', label: '50' },
    { value: '60', label: '60' }]
  const similarCourseOption = [
    { value: 'SENG2250', label: 'SENG2250' },
    { value: 'SENG2260', label: 'SENG2260' },
    { value: 'SENG3500', label: 'SENG3500' }]
  const prerequisiteOption = [
    { value: 'SENG2050', label: 'SENG2050' },
    { value: 'COMP1010', label: 'COMP1010' },
    { value: 'COMP1140', label: 'COMP1140' }]

  const durationList = [
    {id:'0', value:'Summer'},
    {id:'1',value:'Semester1'},
    {id:'2',value:'Semester2'},
    {id:'3',value: 'Winter'}]

  const addGroup = () => {
    setGroup([...group, {}])
  }
  const deleteGroup = (index) => {
    const groupList = [...group];
    groupList.splice(index, 1);
    setGroup(groupList)  
  }
  const eachGroupValue = (e, index) =>{
    const groupList = [...group];
    groupList[index][e] = e;
    setGroup(groupList);
    formik.setFieldValue('prerequisite', [...group, e])
  }

  const formik = useFormik({
    initialValues: {
      courseName: '',
      courseCode:'',
      level: '',
      unit:'',
      prerequisite: '',
      similarCourse: '',
      description: '',
      location: '',
      studyPeriod: duration,
    },
    validationSchema: Yup.object({
      courseName: Yup.string().max(30, "Must be 30 Characters or less").required("Required"),
      courseCode: Yup.string().max(9, "Must be 9 Characters or less").required("Required"),
      level: Yup.string().required("Required"),
      unit: Yup.string().required("Required"),
      location: Yup.array().required("Required"),
      description: Yup.string().max(30, "Must be 30 Characters or less").required("Required"),
      studyPeriod: Yup.array().required("Required"),
    }) ,
    onSubmit: (values) => {
      console.log(values);
      console.log(<Course courseName={values.courseName} level={values.level} 
       prerequisite={values.prerequisite} similarCourse={values.similarCourse} studyPeriod={values.studyPeriod}
       location={values.location}/>)
    },
  })
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
      <div className='text-white text-3xl flex select-none'>
        <div className='absolute top-0 left-0 bg-uon-blue-500 text-white w-full'>
          <label className='ml-1.5'>CREATE NEW COURSE</label>
        </div>
        <div>
          <label 
           htmlFor='create-course-modal'
           className='btn btn-sm absolute right-0 top-0 bg-uon-blue-500 border-uon-blue-500 text-white text-3xl'
        >X</label>
      </div>
      </div>
      <div>
          <div className='grid grid-rows-1 grid-flow-col '>
          <div className='select-none'>
            <div>
              <div className='mt-6 mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
                Course Name
              </div>
              <input
                id='courseName'
                type='text'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                 focus:ring-blue-500 focus:border-blue-500 block w-96 p-2 mb-2.5 dark:bg-gray-700 
                 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Course Name'
                value={formik.values.courseName} 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.courseName && formik.errors.courseName ? <p className='text-error'>{formik.errors.courseName}</p> : null}
            </div>
            <div className='select-none'>
            <div>
              <div className='mt-2 mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
                Course Code
              </div>
              <input
                id='courseCode'
                type='text'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                 focus:ring-blue-500 focus:border-blue-500 block w-96 p-2 mb-2.5 dark:bg-gray-700 
                 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Course Name'
                value={formik.values.courseCode} 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.courseCode && formik.errors.courseCode ? <p className='text-error'>{formik.errors.courseCode}</p> : null}
            </div>
            </div>
            <div className='select-none'>
            <div className='mt-2 text-1xl font-sans text-uon-gray font-bold capitalize'>
              Level
            </div>
            {/* <Select 
               id='level' className=' mb-2.5 text-gray-900 text-lg rounded-lg focus:ring-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
               value={levelOption.find(op => { return op.value === level    })}              
                onChange={(value) => {selectLevel(value.value);}} isSearchable={false} placeholder="Select Level" options={levelOption} value={formik.values.level} 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />*/}
                <SelectLevel
                 isMulti={false}
                 options={levelOption}
                 value={formik.values.level}
                 onChange={values => formik.setFieldValue('level',values.value)}
                 />
                {formik.touched.level && formik.errors.level ? <p className='text-error'>{formik.errors.level}</p> : null}
            </div>
            <div className='select-none'>
                <div className='mt-2 text-1xl font-sans text-uon-gray font-bold capitalize'>
                  Pre-requisite Courses
                </div>
                <div>
                {/* htmlFor='create-Prerequisite-modal' */}
                {/* onClick={() => setOpen(!open)}>Add Prerequisite+</label> */}
                {!open ? <label htmlFor='create-Prerequisite-modal' className='btn rounded-full text-2xl capitalize text-white modal-button'  onClick={() => setOpen(open)}>Add Prerequisite+</label> : ''}
                <input
                type='checkbox'
                id='create-Prerequisite-modal'
                className='modal-toggle'
                />
                <div className='modal'>
                <div className='modal-box'>
                <CreatePrerequisite open={setOpen} group={group} eachGroupValue={eachGroupValue} 
                deleteGroup={deleteGroup} addGroup={addGroup} formik={formik} prerequisiteOption={prerequisiteOption}/>
                </div>
                </div>
                {/* {open ? group.map((eachGroup,index) => (  
                  <div key={index}>
                  <p className='text-2xl'>Group {index + 1}</p>
                  <p>Only one pre-requisite in each group will be required.</p>
                  <div className='justify-left mt-4 flex'>
                  <SelectPrerequisite
                    type="text"
                    id='prerequisite'
                    isMulti={true}
                    options={prerequisiteOption}
                    value={eachGroup.group}
                    onChange={(e) => eachGroupValue(e, index)}
                    />
                  {group.length > 1 && (<label className='btn btn-sm mt-1.5' onClick={() => deleteGroup(index)}>X</label>)}
                  </div>
                  {group.length - 1 === index && group.length < 5 && (<label className='btn text-xl text-white rounded-full' onClick={addGroup}>Add Group  +</label>)}
                   {formik.touched.prerequisite && formik.errors.prerequisite ? <p className='text-error'>{formik.errors.prerequisite}</p> : null}
                  </div>
                )) : "" } */}
                </div>
            <div className='select-none'>
            <div className='mt-2 text-1xl font-sans text-uon-gray font-bold capitalize'>Similar courses</div>
                <SelectSimilarCourses
                 id='similarCourse'
                 isMulti={true}
                 options={similarCourseOption}
                 value={formik.values.similarCourse}
                 onChange={value => formik.setFieldValue('similarCourse', value)}
                 />
                {formik.touched.similarCourse && formik.errors.similarCourse ? <p className='text-error'>{formik.errors.similarCourse}</p> : null}
          </div>
          </div>
          <br />
          </div>

          <div className='select-none ml-3 col-span-2 ...'>
              <div className='mt-6 mb-2.5 text-3xl font-bold capitalize bg-uon-gray text-white'>
                Select Study Periods                   
              </div>        
              <div className='grid grid-cols-3'>
                <div className='text-2xl'>
                <div className='grid grid-cols-1'>       
                <label id='Callahgan' className={!checked ?' bg-white border-white text-center' : 'font-bold text-uon-blue-500 border-white text-center mt-1 mb-1'}  
                onClick={() => checkedLocation('Callahgan', 'Callahgan')}>Callahgan</label> 
                <label id='Singapore' className={!checked2 ?' bg-white border-white text-center' : 'font-bold text-uon-blue-500 border-white text-center mt-1 mb-1'}  
                onClick={() => checkedLocation('Singapore', 'Singapore')}>Singapore</label>
                <label id='Newcastle' className={!checked3 ?' bg-white border-white text-center' : 'font-bold text-uon-blue-500 border-white text-center mt-1 mb-1'}  
                onClick={() => checkedLocation('Newcastle', 'Newcastle')}>Newcastle</label> 
                <label id='Online' className={!checked4 ?' bg-white border-white text-center' : 'font-bold text-uon-blue-500 border-white text-center mt-1 mb-1'}  
                onClick={() => checkedLocation('Online', 'Online')}>Online</label>
                {/* {locationList.map(item => {
                  return ( <label id={item.id} value={item.value} key={item.id}
                  className={!checked ?' mb-1 bg-white border-white text-center' : 
                  'font-bold text-uon-blue-500 border-white text-center mb-1'} 
                  onClick={() => checkedLocation(item.id, item.value)}>
                  {item.value}
                  </label>)}
                )} */}
                </div>
                {formik.touched.location && formik.errors.location ? <p className='text-error'>{formik.errors.location}</p> : null}
                </div>

                { <div>
                  <label className='grid grid-cols-2 gap-80 text-2xl underline underline-offset-4 ...'>
                  <span >Select_All:</span>
                  <input type='checkbox' id='all' unselectable={true} 
                  disabled={((checked || checked2 || checked3 || checked4) ? false : true)} 
                  // onChange{(!All) => (setAll(!All))} change all checkbox
                  onChange={(e) => onCheckedDuration(e.target.checked, e.target.value)}
                  className='w-7 h-7 rounded-lg' value='All'
                  />
                </label>
                {/*<label className='mt-2 text-xl grid grid-cols-2 gap-80'>
                  <span >Winter </span>
                  <input type='checkbox' id='studyPeriod' className='w-7 h-7 rounded-lg' name='checked' value={formik.values.studyPeriod}/>
                </label> */}
                {durationList.map(item => {
                  return ( 
                    <label className='mt-2 text-2xl grid grid-cols-2 gap-80' key={item.id}>{item.value}
                    <input id='studyPeriod' disabled={((checked || checked2 || checked3 || checked4) ? false : true)} className='w-7 h-7 rounded-lg' type='checkbox' value={item.value}
                    onChange={(e) => {onCheckedDuration(e.target.checked, e.target.value)}}
                    checked={duration.includes(item.value) ? true : false}/></label>)}
                )}
                {(checked || checked2 || checked3 || checked4) ? "" : <div><p className='text-error'>must select a degree first.</p></div>}
                {formik.touched.studyPeriod && formik.errors.studyPeriod ? <p className='text-error'>{formik.errors.studyPeriod}</p> : null}
                </div>}
              </div>
              <div>
          <div className='select-none mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
              Amount of Units
          </div>
          <SelectUnit
                 isMulti={false}
                 options={UnitsOption}
                 value={formik.values.unit}
                 onChange={values => formik.setFieldValue('unit',values.value)}
                 />
                {formik.touched.unit && formik.errors.unit ? <p className='text-error'>{formik.errors.unit}</p> : null}
          </div>
            </div>
          </div>
        </div>
          <div>
              <div className='select-none mb-2.5 text-1xl font-sans text-uon-gray font-bold capitalize'>
                Description
              </div>
              <textarea
                id='description'
                type='text'
                className='resize-none text-justify bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-32 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Description'
                value={formik.values.description} 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description ? <p className='text-error'>{formik.errors.description}</p> : null}
          </div>
          <br />
          <div className='flex flex-row gap-2'>
            <label
              htmlFor='create-course-modal'
              className='btn btn-outline w-32 text-2xl capitalize'
            >
              Cancel
            </label>
            <button
              type='submit'
              className='btn btn-primary w-32 text-white text-3xl capitalize'
            >
              Add
            </button>
            </div>
         </form>
    </>
  );
};

export default CourseDetail;