import React, {useEffect, useState} from 'react';
import SearchBar from "../../SearchBar";
import PropTypes from "prop-types";
import AdminCourse from "./AdminCourse"

const AdminCourseSidebar = ({arrayOfData, placeholder, heading, subheading, editHandler, removeHandler}) => {

    const [filteredData, setFilteredData] = useState(arrayOfData || []);

    useEffect(() => {
        setFilteredData(arrayOfData);
    }, [arrayOfData]);

    return (
        <div className={'flex flex-col px-2.5 gap-y-10 py-1.5 border-uon-gray h-full w-full bg-white shadow-lg select-none'}>
            <div className={'flex flex-col gap-y-3.5'}>
                <p className={'font-sans text-2xl underline text-uon-blue-500'}>{heading} </p>
                <p className={'font-sans text-md text-uon-gray'}>{subheading}</p>
            </div>
            <SearchBar placeholder={placeholder} setFilteredData={setFilteredData} arrayOfData={arrayOfData}/>
            <div className={'gap-y-2.5 flex flex-col h-full'}>
                <hr/>
                <div className={'grid grid-cols-2 w-full px-5 content-start gap-2.5 overflow-y-auto scroll-smooth h-96'}>
                    {filteredData.map(data =>
                        <div className={' columns-1'}>
                            <AdminCourse id={data.id} name={data.value2} code={data.value1} location={'sidebar'}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) ;
}

AdminCourseSidebar.propTypes = {
    arrayOfData: PropTypes.arrayOf(PropTypes.shape({
        value1: PropTypes.string.isRequired,
        value2: PropTypes.string,
        id: PropTypes.number.isRequired
    }))
}

export default AdminCourseSidebar;