import Button from '../../Button';
import React from 'react';

const Course = ({ course, onDelete, onAdd }) => {
  return (
    <div id='boxDiv' className={`course ${course.reminder ? 'reminder' : ''}`}>
      <div>
        <h3>{course.code} </h3>
        <div>{course.name} </div>
        <div>{course.prerequisite} </div>
        <div>{course.description} </div>
      </div>
      <div>
        <Button color='blue' text=' Edit ' onClick={() => onAdd(course.id)} />
        <Button color='red' text='Delete' onClick={() => onDelete(course.id)} />
      </div>
    </div>
  );
};
export default Course;
