
class WarningClass{
    /**
     *
     * @param type {WarningType}
     * @param courseId {int}
     * @param studyPeriodId {int}
     * @param {int[]} [groupOfPreReqIds] unmet pre-reqs of the course associated with courseId
     * @param {int} [unitsOverBy] the amount of units that the study-period is over by
     */
    constructor(type, courseId, studyPeriodId, groupOfPreReqIds = undefined, unitsOverBy = undefined) {
        this.type = type;
        this.courseId = courseId;
        this.studyPeriodId = studyPeriodId;
        this.groupOfPreReqIds = groupOfPreReqIds;
        this.unitsOverBy = unitsOverBy;
    }

    /**
     *
     * @param courses {CourseClass[]}
     */
    getDropZoneMessage = (courses) => {
        const courseCode = courses.find(course => course.courseId === this.courseId).courseCode;
        switch (this.type){
            case WarningType.NO_PROBLEMS:
                return 'Drop here';
            case WarningType.OVER_UNITS:
                return 'This study period will be over pref units.';
            case WarningType.PRE_REQS_NOT_MET:
                return 'The pre-requisite courses are not met for '+ courseCode;
            case WarningType.SIMILAR_COURSE_COMPLETED:
                return 'Similar course has already been completed.';
            case WarningType.NOT_AVAILABLE_IN_STUDY_PERIOD:
                return courseCode + ' is not available in this study period.';
            case WarningType.SIMILAR_COURSE_IN_A_STUDY_PERIOD:
                return 'Similar course has already been added to a study period';
            case WarningType.ALREADY_COMPLETED:
                return courseCode + '\nhas already\nbeen\ncompleted.';
            case WarningType.ALREADY_IN_A_STUDY_PERIOD:
                return courseCode + '\nhas already been\nadded to a\nstudy period.';
        }

    }

    /**
     * converts pre-req warnings and units warning into strings that can be displayed in a tooltip
     * @param courses {CourseClass[]}
     * @returns {string}
     */
    getTooltipMessage = (courses) => {
        if(this.type === WarningType.PRE_REQS_NOT_MET){
            const parentCourseCode = courses.find(course => course.courseId === this.courseId).courseCode;
            if (this.groupOfPreReqIds.length === 1) {
                const reqCourseId = this.groupOfPreReqIds[0];
                let preReqCourseCode = courses.find(course => course.courseId === reqCourseId).courseCode;
                return `Requisite ${preReqCourseCode} is not met for ${parentCourseCode}.`;
            }
            if (this.groupOfPreReqIds.length > 1) {
                const courseCodes = [];
                this.groupOfPreReqIds.forEach(reqCourseId => courseCodes.push(courses.find(course => course.courseId === reqCourseId).courseCode));
                let message = 'Requisite';
                courseCodes.forEach((courseCode, index) => {
                    message += index === courseCodes.length - 1 ? ` ${courseCode}` : ` ${courseCode} or`;
                })
                message += ` not met for ${parentCourseCode}`
                return message;
            }
        }
        else if(this.type === WarningType.OVER_UNITS){
            return `This Study-Period is over units preference by ${this.unitsOverBy} units.`
        }
    }

}

class WarningType {

    static NO_PROBLEMS = new WarningType('this warning is not actually a warning');
    static PRE_REQS_NOT_MET = new WarningType('pre-reqs are not met in this study-period for this course');
    static NOT_AVAILABLE_IN_STUDY_PERIOD = new WarningType('course is not available in this study period');
    static OVER_UNITS = new WarningType('study-period is over units');
    static ALREADY_IN_A_STUDY_PERIOD = new WarningType('this course is already in this study period');
    static ALREADY_COMPLETED = new WarningType('this course is already completed');
    static SIMILAR_COURSE_COMPLETED = new WarningType('similar course already completed');
    static SIMILAR_COURSE_IN_A_STUDY_PERIOD = new WarningType('similar course in study period');

    /**
     * enum that tells WarningClass the type of a warning
     * @param warningType {string}
     */
    constructor(warningType) {
        this.warningType = warningType
    }
}

export {WarningClass as default, WarningType}