import Course from '../components/Student/Course'
class CourseClass {
    /**
     * @type {int}
     */
    courseId;
    /**
     * @type {string}
     */
    courseCode;
    /**
     * @type {string}
     */
    courseName;
    /**
     * @type {string}
     */
    description;
    /**
     * @type {int}
     */
    units;
    /**
     * @type {string}
     */
    level;
    /**
     * @type {boolean}
     */
    isOfferedAsElective;
    /**
     * @type {string[]}
     */
    availabilities;
    /**
     * @type {string}
     */
    courseType;
    /**
     * @type {int[][]}
     */
    groupsOfRequiredCourseIds;
    /**
     * @type {int[]}
     */
    similarCourses;
    /**
     * @type {boolean}
     */
    isCompleted;
    /**
     * @type {int}
     */
    preRequisiteUnits

    /**
     *
     * @param courseId {int}
     * @param courseCode {string}
     * @param courseName {string}
     * @param description {string}
     * @param units {int}
     * @param level {string}
     * @param isOfferedAsElective {boolean}
     * @param availabilities {string[]}
     * @param courseType {string}
     * @param groupsOfRequiredCourseIds {int[][]}
     * @param similarCourseIds {int[]}
     * @param completed {boolean}
     * @param preRequisiteUnits {int}
     */
    constructor(courseId, courseCode, courseName, description, units, level, isOfferedAsElective, availabilities, courseType, groupsOfRequiredCourseIds, similarCourseIds, completed, preRequisiteUnits) {
        this.courseId = courseId;
        this.courseCode = courseCode;
        this.courseName = courseName;
        this.description = description;
        this.units = units;
        this.level = level;
        this.isOfferedAsElective = isOfferedAsElective;
        this.availabilities = availabilities;
        this.courseType = courseType;
        this.groupsOfRequiredCourseIds = groupsOfRequiredCourseIds;
        this.similarCourseIds = similarCourseIds;
        /**
         *
         * @type {boolean}
         */
        this.completed = completed;
        this.preRequisiteUnits = preRequisiteUnits;
    }

    get isCompleted() {
        return this.isCompleted;
    }

    get groupsOfPreReqIds(){
        return this.groupsOfRequiredCourseIds;
    }

    setCompleted(){
        this.completed = true;
    }

    setInCompleted(){
        this.completed = false;
    }

    /**
     *
     * @param studyPeriodId {int}
     * @param courseCodeOnClick {function(SyntheticInputEvent)}
     * @return {JSX.Element}
     */
    mapToComponent(studyPeriodId, courseCodeOnClick){
        return (
            <Course id={this.courseId} name={this.courseName} code={this.courseCode} type={this.courseType} courseCodeOnClick={courseCodeOnClick} studyPeriodId={studyPeriodId} isCompleted={this.completed} />
        )
    }

    /**
     *
     * @param availabilities {{availabilityId: string, location: string, studyPeriod: string}[]}
     * @return {{location: string, studyPeriod: string[]}[]}
     */
    getAvailabilities(availabilities){
        const processedAvailabilities = [];
        const courseAvailabilities = availabilities.filter(availability => this.availabilities.includes(availability.availabilityId));
        const locations = [];
       courseAvailabilities.forEach(courseAvailability => {
           if(!locations.includes(courseAvailability.location)) {
               locations.push(courseAvailability.location);
               processedAvailabilities.push({location: courseAvailability.location, studyPeriods: []});
           }
           processedAvailabilities.forEach(processedAvailability => {
               if (processedAvailability.location === courseAvailability.location)
                   processedAvailability.studyPeriods.push(courseAvailability.studyPeriod);
           })

       });
       return processedAvailabilities;
    }

}

export default CourseClass;