import Detail from './Detail';

const CourseDetails = ({ course, setCourse, setOpenCourseInfo, courseCodeOnClick}) => {
  return course !== null ? (
    <div className='bg-white shadow-lg px-2 select-none h-full'>
      <Detail course={course} courseCodeOnClick={courseCodeOnClick}>{/*{close.children}*/}</Detail>
      <button
        className={'btn btn-outline w-full'}
        onClick={() => {
          setCourse(null);
          setOpenCourseInfo(false);
        }}
      >
        Close
      </button>
    </div>
  ) : (
    <></>
  );
};

export default CourseDetails;
