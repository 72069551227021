import { ReactComponent as UonLogo } from '../../assets/svg/uonLogo.svg';
import { HiOutlineCog } from 'react-icons/hi';
import { IconContext } from 'react-icons';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import CreateCourse from './Course/CreateCourse';
import CreateOption from './Option/CreateOption';
import CreateDegree from './Degree/CreateDegree';
import { useState } from 'react';


const AdminHeader = (getOptions) => {
  const [openForm, setOpenForm] = useState(false);
  const auth = getAuth();

  // auth.currentUser.email

  const navigate = useNavigate();

  const onLogout = () => {
    auth.signOut();
    navigate('/');
  };
  return (
    <>
      {/* Main Header */}
      <div
        className={
          'flex flex-row justify-between py-1 pr-1.5 shadow-lg h-max z-20'
        }
      >
        <UonLogo />

        {/* Heading text */}
        <div className={'flex items-center'}>
          <h1 className={'text-uon-blue-500 font-sans text-4xl font-bold'}>
            my
          </h1>
          <h1
            className={
              'text-stone-600 font-sans normal-case font-bold text-4xl'
            }
          >
            PROGRAM Admin
          </h1>
        </div>

        {/* Logout button & settings cog */}
        <div className={'flex items-center gap-x-2.5'}>
          <IconContext.Provider value={{ size: '45px' }}>
            <button className='btn btn-primary text-white' onClick={onLogout}>
              Logout
            </button>
            <HiOutlineCog
              className={
                'text-stone-500 hover:bg-blue-50 cursor-pointer p-0.5 rounded-md'
              }
            />
          </IconContext.Provider>
        </div>
      </div>

      {/* Create Header */}
      <div className='flex flex-row justify-center gap-2 bg-uon-gray py-2'>
        {/* Create Course */}
        <label
          htmlFor='create-course-modal'
          className='btn btn-primary text-white modal-button'
        >
          Create Course
        </label>

        <input
          type='checkbox'
          id='create-course-modal'
          className='modal-toggle'
        />
        <div className='modal'>
          <div className='modal-box w-11/12 max-w-5xl'>


            {/* Content here */}
            <CreateCourse getOptions={getOptions} />
          </div>
        </div>

        {/* Create Option */}
        <label
          htmlFor='create-option-modal'
          className='btn btn-primary text-white modal-button'
        >
          Create Option
        </label>

        <input
          type='checkbox'
          id='create-option-modal'
          className='modal-toggle'
        />
        <div className='modal'>
          <div className='modal-box relative'>
            {/* Content here */}
            <CreateOption />
          </div>
        </div>

        {/* Create Degree */}

        <label
          htmlFor='create-degree-modal'
          className='btn btn-primary text-white modal-button'
        >
          Create Degree
        </label>
        <input
          type='checkbox'
          id='create-degree-modal'
          className='modal-toggle'
        />
        <div className='modal'>
          <div className='modal-box relative'>

            <CreateDegree closeForm={openForm}/>
           </div>
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
        {/* <input
           type='checkbox'
          id='create-degree-modal'
          className='modal-toggle'
        /> */}